import React from "react"

import {Box, CircularProgress} from "@mui/material";


export default function LoadingMessage({msg}) {

  return (
      <Box sx={{ display: 'flex', justifyContent: "center", alignItems:"center" }}>
          <CircularProgress style={{padding: "8px"}} />
          <span style={{marginLeft:"5px", fontStyle: "italic", color:"grey", fontSize:"0.8em"}}>
              {msg}
          </span>
      </Box>
  )
}
