import {
    calcIsBankHoliday, convertToServerLocalDateString,
    convertToShortPrettyDate, convertToShortPrettyDateOrTodayString,
    formatSimpleTime, isToday,
    parseJsonTimeToDate
} from "./DateFunctions";
import {
    doNothing,
    fetchAvailability,
    ServiceEnumSubscription,
    ServiceEnumTableBooking,
    ServiceEnumSingleOrder, EstablishmentActive
} from "./GeneralFunctions";

export const fetchAvailabilityLineByDayOfWeek = (availability, i) => {
    switch (i) {
        case 0: return availability.sundayAvailability
        case 1: return availability.mondayAvailability
        case 2: return availability.tuesdayAvailability
        case 3: return availability.wednesdayAvailability
        case 4: return availability.thursdayAvailability
        case 5: return availability.fridayAvailability
        case 6: return availability.saturdayAvailability
        default: alert("ERROR: invalid index:"+i)
    }
}

export const fetchTodaysAvailabilityLine = (availabilities, availabilityRef) => {
    return fetchAvailabilityLineForDate (availabilities, availabilityRef, new Date())
}

export const fetchAvailabilityLineForDate = (availabilities, availabilityRef, date) => {
    const availability = fetchAvailability(availabilities, availabilityRef)
    return fetchAvailabilityLineForDateByAvailability(availability, date)
}

export const fetchAvailabilityLineForDateByAvailability = (availability, date) => {
    const dateString = convertToServerLocalDateString(date)
    const availabilityOverride = availability.overrides[dateString]
    // console.log("Looked for override ("+dateString+") found:"+availabilityOverride)
    const isBankHoliday = calcIsBankHoliday(date)
    const availabilityLine = availabilityOverride ? availabilityOverride
        : isBankHoliday ? availability.bankHolidayAvailability
        : fetchAvailabilityLineByDayOfWeek(availability, date.getDay())
    return availabilityLine
}

export const fetchTimeSlotsForService = (dayAvailability, serviceEnum, isDelivery) => {
    // alert(serviceEnum + ", delivery:"+isDelivery)
    switch (serviceEnum) {
        case ServiceEnumSingleOrder: return isDelivery ? dayAvailability.takeawayDelivery : dayAvailability.takeawayCollection
        case ServiceEnumSubscription: return isDelivery ? dayAvailability.repeatDeliveryWindows : dayAvailability.repeatDeliveryWindows
        case ServiceEnumTableBooking: return dayAvailability.tableBooking
        default: return ""
    }
}

function fetchTypicalTimeToPrepare(establishment, serviceEnum, isDelivery) {
    switch (serviceEnum) {
        case ServiceEnumSingleOrder: return isDelivery ? establishment.takeawaysDetails.deliveryDetails.typicalTimeToPrepare : establishment.takeawaysDetails.collectionDetails.typicalTimeToPrepare
        case ServiceEnumSubscription: return isDelivery ? establishment.repeatOrdersDetails.deliveryDetails.typicalTimeToPrepare : establishment.repeatOrdersDetails.collectionDetails.typicalTimeToPrepare
        case ServiceEnumTableBooking: return 0 //This should not be called?
        default: return ""
    }
}

function fetchSortedSlotsForDate(establishment, availabilities, availabilityRef, date, serviceEnum, isDelivery) {
    const dayAvailability = fetchAvailabilityLineForDate(availabilities, availabilityRef, date)
    const timeSlotsList = fetchTimeSlotsForService(dayAvailability, serviceEnum, isDelivery)
    // alert("date:"+date+", service:"+serviceEnum+", isDelivery:"+isDelivery+", day availability:"+JSON.stringify(dayAvailability)+ ", SLOTS: "+JSON.stringify(timeSlotsList))
    const typicalTimeToPrepare = fetchTypicalTimeToPrepare(establishment, serviceEnum, isDelivery)
    return !timeSlotsList ? [] : timeSlotsList.flatMap(timeWindow =>
        calculatePossibleSelectionTimesWithinWindow(date, timeWindow, typicalTimeToPrepare)
    ).sort()
}

export const calculatePossibleSelectionDates = (establishment, availabilities, availabilityRef, serviceEnum, isDelivery) => {
    let possibleSelectionDates = []
    for (const index of Array.from(Array(7).keys())) {
        const selectionDate = new Date()
        selectionDate.setDate(selectionDate.getDate() + index)

        let slots = fetchSortedSlotsForDate(establishment, availabilities, availabilityRef, selectionDate, serviceEnum, isDelivery)
        // alert(JSON.stringify(slots))
        if (slots && slots.length > 0)
            possibleSelectionDates.push({ date: selectionDate, dateString: convertToServerLocalDateString(selectionDate), prettyDateString: convertToShortPrettyDateOrTodayString(selectionDate)} )
    }
    return possibleSelectionDates
}

export const calculatePossibleSelectionTimes = (establishment, availabilities, availabilityRef, serviceEnum, isDelivery, date) => {
    let possibleSelectionTimes = fetchSortedSlotsForDate(establishment, availabilities, availabilityRef, date, serviceEnum, isDelivery)
        .map(s => formatSimpleTime(s) )
    return possibleSelectionTimes
}

export const fetchNextOpenDate = (establishment, availabilities, availabilityRef, serviceEnum, isDelivery) => {
    let currentDate = new Date()
    for (const index of Array.from(Array(6).keys())) {
        doNothing(index)
        currentDate.setDate(currentDate.getDate() + 1)
        const sortedSlots = fetchSortedSlotsForDate(establishment, availabilities, availabilityRef, currentDate, serviceEnum, isDelivery)
        if (sortedSlots.length > 0)
            return convertToShortPrettyDate(currentDate)
    }
    return
}

function calculatePossibleSelectionTimesWithinWindow(date, timeWindow, typicalTimeToPrepare) {
    let start = parseJsonTimeToDate(timeWindow.from)

    if (isToday(date)) {
        const earliestTimeToComplete = new Date()
        earliestTimeToComplete.setMinutes(earliestTimeToComplete.getMinutes() + typicalTimeToPrepare)
        if (earliestTimeToComplete > start) {
            console.log("earliest time after start")
            start = earliestTimeToComplete
        }
    }

    while (start.getMinutes() !== 0 && start.getMinutes() !== 15 && start.getMinutes() !== 30 && start.getMinutes() !== 45) {
        start.setMinutes(start.getMinutes() + 1)
    }

    const finish = parseJsonTimeToDate(timeWindow.to)
    let slots = []
    while (true) {
        if (start > finish)
            return slots
        slots.push(new Date(start.getTime()))
        start.setMinutes(start.getMinutes() + 15)
    }
}

export const fetchAllAvailabilityLines = (availability) => {
    return [availability.sundayAvailability, availability.mondayAvailability, availability.tuesdayAvailability, availability.wednesdayAvailability, availability.thursdayAvailability, availability.fridayAvailability, availability.saturdayAvailability]
}

export const AvailabilityWindowRepeatDelivery = "Repeat Delivery"
export const AvailabilityWindowRepeatCollection = "Repeat Collection"
export const AvailabilityWindowTableBooking = "Table Reservations"
export const AvailabilityWindowTakeawayCollection = "Takeaway Collection"
export const AvailabilityWindowTakeawayDelivery = "Takeaway Delivery"
export const AvailabilityWindowCoreServices = "Core Services"

export const fetchWindowsByRelevantServices = (availabilityLine, availabilityWindowName) => {
    // alert(JSON.stringify(availabilityLine))
    switch (availabilityWindowName) {
        case AvailabilityWindowRepeatDelivery: return [availabilityLine.repeatDeliveryWindows]
        case AvailabilityWindowRepeatCollection: return [availabilityLine.repeatCollectionWindows]
        case AvailabilityWindowTableBooking: return [availabilityLine.tableBooking]
        case AvailabilityWindowTakeawayCollection: return [availabilityLine.takeawayCollection]
        case AvailabilityWindowTakeawayDelivery: return [availabilityLine.takeawayDelivery]
        case AvailabilityWindowCoreServices: return [availabilityLine.repeatCollectionWindows, availabilityLine.tableBooking, availabilityLine.takeawayCollection, availabilityLine.takeawayDelivery]
        default: alert("Error: unknown service name (" + availabilityWindowName + ")"); return []
    }
}

export const fetchOverride = (availability, selectedDate) => {
    const d = convertToServerLocalDateString(selectedDate)
    return availability.overrides[d]
}

export const isCurrentlyOpen = (timeSlotsList) => timeSlotsList.some(timeSlot => fetchNextOpeningTimeFromSlot(timeSlot).isCurrentlyOpen)

export const fetchNextOpeningTime = (timeSlotsList) => {
    const nextOpeningTimes = new Set()
    timeSlotsList.forEach((v,k) => {
        const openingTime = fetchNextOpeningTimeFromSlot(v)
        if (openingTime.isCurrentlyOpen)
            return
        else if (openingTime.nextOpeningTime)
            nextOpeningTimes.add(openingTime.nextOpeningTime)
    })
    return Array.from(nextOpeningTimes).sort().map(s => formatSimpleTime(s))[0]
}

export const fetchNextOpeningTimeFromSlot = (timeWindow) => {
    const start = parseJsonTimeToDate(timeWindow.from)
    const finish = parseJsonTimeToDate(timeWindow.to)
    const now = new Date()
    if (finish <= now)
        return {isCurrentlyOpen: false, nextOpeningTime: null} //Already Closed
    if (start > now)
        return {isCurrentlyOpen: false, nextOpeningTime: start} // start is in the future
    return {isCurrentlyOpen: true, nextOpeningTime: null} // already open
}

export const calculateOrderingDisabled = (menuConfig) => {
    return menuConfig.establishment.activeStatus !== EstablishmentActive || !menuConfig.canDeliverOrCollect
}

export const calculateClosedMsg = (menuConfig) => {

    const basket = menuConfig.basket
    const menu = menuConfig.selectedMenu
    const establishment = menuConfig.establishment

    if (!menuConfig.canDeliverOrCollect)
        return "We are not currently taking orders"
    //TODO R2: - for the message above, present nearby alternatives!
    if (!menuConfig.isSingleOrder)
        return ""

    const dayAvailability = fetchTodaysAvailabilityLine(menuConfig.availabilities, menu.availabilityRef)
    const timeSlotsList = fetchTimeSlotsForService(dayAvailability, basket.serviceOfferingEnum, basket.deliverySelected)
    if (isCurrentlyOpen(timeSlotsList)) {
        return ""
    }


    if (!timeSlotsList || timeSlotsList.length === 0) { //Closed today, but find next open date to report...
        const nextOpenDate = fetchNextOpenDate(establishment, menuConfig.availabilities, menu.availabilityRef, basket.serviceOfferingEnum, basket.deliverySelected)
        if (nextOpenDate)
            return ((basket.deliverySelected ? "Delivery" : "Collection") + " closed today, but you can preorder for "+nextOpenDate)
        else
            return (basket.deliverySelected ? "Delivery" : "Collection") + " from this menu is currently closed"
    }

    const nextOpeningTime = fetchNextOpeningTime(timeSlotsList)
    if (nextOpeningTime) {
        return "Opening at " + nextOpeningTime + ". Preorder now."
    }

    const nextOpenDate = fetchNextOpenDate(establishment, menuConfig.availabilities, menu.availabilityRef, basket.serviceOfferingEnum, basket.deliverySelected)
    if (nextOpenDate)
        return establishment.name+" is not taking orders today, but you can preorder for " + nextOpenDate
    else
        return (basket.deliverySelected ? "Delivery" : "Collection") + " from this menu is not currently available. " + establishment.name + " has multiple menus, so why not try a different one."
}

export const fetchTakeawayOrRepeatDetails = (menuConfig) => {
    return menuConfig.isSingleOrder ? menuConfig.establishment.takeawaysDetails
        : menuConfig.isSubscription ? menuConfig.establishment.repeatOrdersDetails : Error("Control only support single and repeat orders")
}

export const fetchUsersPostcodeDistrict = (takeawayOrRepeatDetails, currentUser, basket) => {
    const postcode = basket.customerPostcode ? basket.customerPostcode : currentUser.loggedIn ? currentUser.location.postcode : ""
    return postcode ? postcode.split(" ")[0] : ""
}
export const fetchIsWithinDeliveryPostcodes = (takeawayOrRepeatDetails, usersPostcodeDistrict) => {
    const deliveryDetails = takeawayOrRepeatDetails.deliveryDetails
    return usersPostcodeDistrict && deliveryDetails && deliveryDetails.deliverToPostcodeDistricts.includes(usersPostcodeDistrict)
}
export const fetchIsWithinMaxDeliveryDistance = (takeawayOrRepeatDetails, currentUser, distance) => {
    const deliveryDetails = takeawayOrRepeatDetails.deliveryDetails
    return !currentUser.loggedIn || (!deliveryDetails || !deliveryDetails.maxDeliveryDistance || distance < deliveryDetails.maxDeliveryDistance)
}
export const fetchCanDeliver = (takeawayOrRepeatDetails, currentUser, basket) => {
// alert (basket.distance+", "+deliveryDetails.maxDeliveryDistance)
    const deliveryDetails = takeawayOrRepeatDetails.deliveryDetails
    if (!deliveryDetails ||
        !deliveryDetails.enabled)
        return false

    const usersPostcodeDistrict = fetchUsersPostcodeDistrict(takeawayOrRepeatDetails, currentUser, basket)

    const isWithinDeliveryPostcodes = fetchIsWithinDeliveryPostcodes(takeawayOrRepeatDetails, usersPostcodeDistrict)
    const isWithinMaxDeliveryDistance = fetchIsWithinMaxDeliveryDistance(takeawayOrRepeatDetails, currentUser, basket.distance)

    const isInDeliveryRange = (!usersPostcodeDistrict) || (isWithinDeliveryPostcodes && isWithinMaxDeliveryDistance)

    return isInDeliveryRange
}
export const fetchCanCollect = (takeawayOrRepeatDetails) => {
    return takeawayOrRepeatDetails.collectionDetails && takeawayOrRepeatDetails.collectionDetails.enabled
}
