import {closeBackdropSpinner, openBackdropSpinner} from "./GlobalStateFunctions";
import React from "react";
import {Box} from "@mui/material";

export const ServiceEnumSingleOrder = "SingleOrder"
export const ServiceEnumSubscription = "Subscription"
export const ServiceEnumTableBooking = "TableBooking"
export const ServiceEnumPreorder = "Preorder"
export const ServiceEnumPayAtTable = "PayAtTable"

export const basketApplicableServiceEnums = [ServiceEnumSingleOrder, ServiceEnumSubscription, ServiceEnumTableBooking, ServiceEnumPreorder]

export const convertServiceEnumToLabel = (serviceEnumName) => {
    switch(serviceEnumName) {
        case ServiceEnumSingleOrder: return "Single Order"
        case ServiceEnumSubscription: return "Subscription"
        case ServiceEnumTableBooking: return "Table Booking"
        case ServiceEnumPreorder: return "Preorder"
        case ServiceEnumPayAtTable: return "Pay At Table"

        default: return serviceEnumName
    }
}

export const serviceEnumToSelectedHeaderEnum = (serviceEnum) => {
    switch(serviceEnum) {
        case ServiceEnumSingleOrder: return "Order"
        case ServiceEnumSubscription: return "Order"
        case ServiceEnumTableBooking: return "BookTable"

        default: return serviceEnum
    }
}

export const EstablishmentActive = "Active"
export const EstablishmentUnregistered = "Unregistered"
export const EstablishmentAdminBlocked = "AdminBlocked"
export const EstablishmentDeactivatedByUser = "DeactivatedByUser"

export const establishmentActiveEnums = [EstablishmentActive, EstablishmentUnregistered, EstablishmentAdminBlocked, EstablishmentDeactivatedByUser]

export const convertBusinessTypeEnumToPretty = (businessTypeEnum, requiresSingular) => {
    switch(businessTypeEnum) {

        case "HospitalsChildcareCaring": return requiresSingular ? "Hospital, Childcare or Care Home" : "Hospitals, Childcare and Care Homes"
        case "SchoolCollegeUniversity": return requiresSingular ? "School or University" : "Schools and Universities"
        case "DistributorsTransporters": return requiresSingular ? "Distributor" : "Distributors"
        case "SupermarketsHypermarkets": return requiresSingular ? "Supermarket" : "Supermarkets"
        case "HotelBedAndBreakfastGuestHouse": return requiresSingular ? "Hotel or Guest House" : "Hotels and Guest Houses"
        case "ImportersExporters": return requiresSingular ? "Importer or Exporter" : "Importers and Exporters"


        case "FarmersAndGrowers": return requiresSingular ? "Grower" : "Growers"
        case "ManufacturersAndPackers": return requiresSingular ? "Food Manufacturer" : "Food Manufacturers"
        case "MobileCaterer": return requiresSingular ? "Mobile Caterer" : "Mobile Caterers"

        case "OtherCateringPremises": return requiresSingular ? "Caterer" : "Caterers"
        case "PubBarNightclub": return requiresSingular ? "Bar" : "Bars"
        case "RestaurantCafeCanteen": return requiresSingular ? "Restaurant or Cafe" : "Restaurants and Cafes"

        case "RetailersOther": return requiresSingular ? "Retailer" : "Retailers"
        case "TakeawaysAndSandwichShop": return requiresSingular ? "Takeaway" : "Takeaways"
        case "Hairdressers": return requiresSingular ? "Hairdresser" : "Hairdressers"

        default: return businessTypeEnum
    }
}

export const reportErrorRectificationWithServer = (setGlobalState, detail) => {
    console.log("Reporting rectification of error with server: "+detail)

    return makePost(setGlobalState,
        '/api/report-rectified-error',
        JSON.stringify({ usersHref: window.location.href, detail:detail,}),
        _ => { closeBackdropSpinner(setGlobalState) }
    )
}

export const reportErrorWithServer = (setGlobalState, detail) => {
    console.log("Reporting error with server: "+detail)

    return makePost(setGlobalState,
        '/api/report-error-generated',
        JSON.stringify({ usersHref: window.location.href, detail:detail, }),
        _ => { closeBackdropSpinner(setGlobalState) }
    )
}

export const makePostWithFile = (setGlobalState, url, formData, successFunction, errorFunction) => {
    // alert("Debug JSON post: " + JSON.stringify(body))

    return fetch(url, {
        credentials: 'include',
        method: 'POST',
        headers: {'X-XSRF-TOKEN': fetchCookie("XSRF-TOKEN")},
        body: formData,
    })
        .then(response => {if(response.ok) { return response.json() } else throw new Error('Request to POST message to server failed')})
        .then(json => {if (json.success) return json; else throw new Error('Server returned success=false')})
        .then(json => successFunction(json))
        .catch(e => {
            if (!url)
                alert("Error: URL was empty")
            if (url && !url.includes("/api/report-error"))
                reportErrorWithServer(setGlobalState, "<div>Exception occurred making POST to:</div><div>"+url+"</div><div>Captured exception: "+e+"</div>")
            if (errorFunction) {
                errorFunction(e)
            } else {
                alert("We are sorry but a POST with file error has occurred. This error has been flagged to the technical support team, but we are extremely sorry for any inconvenience: "+e)
            }
        } )
}

export const fetchCookie = (cookieName) => {
    let parts = decodeURIComponent(document.cookie).split(';')
    for(let i = 0; i <parts.length; i++) {
        let part = parts[i]
        while (part.charAt(0) === ' ') {
            part = part.substring(1)
        }
        if (part.indexOf(cookieName + "=") === 0) {
            return part.substring(cookieName.length+1, part.length);
        }
    }
    return ""
}
export const makePostForFlux = (setGlobalState, url, body, successFunction) => {
    const xsrfToken = fetchCookie("XSRF-TOKEN")
    return fetch(url, {
        method: 'POST',
        headers: {'X-XSRF-TOKEN': xsrfToken, 'Accept': 'application/json', 'Content-Type': 'application/json'},
        body: body
    })
        .then(res => {
            return successFunction(res.text())
        }).finally(() => {
                if (setGlobalState)
                    closeBackdropSpinner(setGlobalState)
            }
        )
}
export const makePost = (setGlobalState, url, body, successFunction, errorFunction) => {
    // alert("Debug JSON post: " + JSON.stringify(body))
    const xsrfToken = fetchCookie("XSRF-TOKEN")
    // alert("CSRF: "+ xsrfToken)
    return fetch(url, {
        // credentials: 'include',
        method: 'POST',
        headers: {'X-XSRF-TOKEN': xsrfToken, 'Accept': 'application/json', 'Content-Type': 'application/json'},
        body: body
    })
        .then(response => {
            console.log("made post to: "+url)
            if(response.ok) {
                return response.json() }
            else
                throw new Error('Request to POST message to server failed')
        })
        .then(json => {if (json.success) return json; else { throw new Error(json.errorMessage) } })
        .then(json => successFunction(json))
        .catch(e => {
            if (!url)
                alert("Error: URL was empty")
            if (url && !url.includes("/api/report-error"))
                reportErrorWithServer(setGlobalState, "An exception occurred making POST to "+url+". Captured exception: "+e)
            if (errorFunction)
                errorFunction(e.message)
        }).finally(() => {
            if (setGlobalState)
                closeBackdropSpinner(setGlobalState)
            }
        )
}
export const makePut = (setGlobalState, url, body, successFunction) =>
    // alert("Debug JSON post: " + JSON.stringify(body))
    fetch(url, {
        credentials: 'include',
        method: 'PUT',
        headers: {'X-XSRF-TOKEN': fetchCookie("XSRF-TOKEN"), 'Accept': 'application/json', 'Content-Type': 'application/json', },
        body: body
    })
        .then(response => {if(response.ok) { return response.json() } else throw new Error('Request to PUT message to server failed')})
        .then(json => {if (json.success) return json; else throw new Error('Server returned success=false')})
        .then(json => successFunction(json))
        .catch(e => {
            reportErrorWithServer(setGlobalState, "An exception occurred making PUT to "+url+". Captured exception: "+e)
            alert("We are sorry but an error has occurred. This error has been flagged to the technical support team, but we are extremely sorry for any inconvenience: "+e)
        } )

export function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            { value === index && (<Box sx={{ p: 3 }}>{children}</Box>) }
        </div>
    )
}

export const makeFetch = (setGlobalState, url, successFunction, errorMsg) =>
    makeFetchWithErrorFunction(setGlobalState, url, successFunction, () => { if (errorMsg) console.error(errorMsg) } )

const MAX_RETRIES = 8
export function makeFetchWithErrorFunction(setGlobalState, url, successFunction, errorFunction, retryRecorder) {
    return fetch(fullUrl(url)).then(res => {
            console.log("made fetch to: "+url)
            return res.text()
        }).then(text => {
            if (!text) {
                throw Error("Server error. No JSON response: "+ url)
            } else {
                return JSON.parse(text)
            }
        }).then(json => {
            if (successFunction)
                successFunction(json)
            return true
        }).catch(ex => {
            // retryFetch(setGlobalState, url, successFunction, errorFunction, retryRecorder, ex)
            const errorString = "<div>Failure making GET: " + fullUrl(url) + "</div><div>From: "+window.location.href+"</div><br /><div>Captured exception:</div>" + ex + "</div>"
            reportErrorWithServer(setGlobalState, errorString)
                .finally(() => { if (errorFunction) errorFunction(ex) } )
            return false
        }).finally(() => {
            if (setGlobalState)
                closeBackdropSpinner(setGlobalState)
        }
    )
}

function retryFetch(setGlobalState, url, successFunction, errorFunction, retryRecorder, ex) {
    if (retryRecorder === undefined)
        retryRecorder = { retryNumber: 0, exceptions: [] }
    // alert("retry no:"+retryRecorder.retryNumber)
    console.log("retry no:"+retryRecorder.retryNumber)
    let timeout
    switch (retryRecorder.retryNumber) {
        case 0: timeout=0; break;
        case 1: timeout=3; break;
        case 2: timeout=10; break;
        case 3: timeout=50; break;
        case 4: timeout=200; break;
        case 5: timeout=1000; break;
        default: timeout = 2000; break;
    }

    setTimeout( () => {
        retryRecorder.retryNumber++
        retryRecorder.exceptions.push(ex)
        if (retryRecorder.retryNumber >= MAX_RETRIES) {
            reportErrorWithServer(setGlobalState, "" +
                    "<div>Failure making GET: " + fullUrl(url) + "</div><div>From: "+window.location.href+"</div><br />" +
                    "<div>Captured exceptions:</div>" + retryRecorder.exceptions.map (e => "<div>"+e+"<br /><hr /><br /></div>" ) +
                    "<div>Retries terminated after " + retryRecorder.retryNumber + " attempts (retry timeout = "+timeout+"ms)</div>"
                ).catch(_ => { reportErrorWithServer(setGlobalState, "Attempted to report error - failed 1st time, but succeeded this time - giving up!") } )
                .finally(() => { if (errorFunction) errorFunction(ex) } )
        } else {
            makeFetchWithErrorFunction(setGlobalState, url, successFunction, errorFunction, retryRecorder)
                .then(completedSuccessfully => {
                    // if (completedSuccessfully)
                    //     reportErrorRectificationWithServer(setGlobalState, "Attempt to fetch ("+fullUrl(url)+") failed, but then succeeded on retry: "+retryRecorder.retryNumber + "<div>Captured exceptions:</div>" + retryRecorder.exceptions.map (e => "<div>"+e+"<br /><hr /><br /></div>" ) )
                })
                .finally(() => { if (errorFunction) errorFunction(ex) } )
        }
    }, timeout)
}

function fullUrl(url) {
    return url

    // if (url.includes("https://"))
    //     return url
    //
    // const urlParts = window.location.href.split("/")
    // const domain = urlParts[2]
    //
    // const fullUrl = "https://" + domain + url
    // // alert("part:"+url+",full:"+fullUrl)
    // return fullUrl
}

export const triggerLogout = () => {
    fetch('/api/logout').then( () => window.location.href = "/" ).catch( () => window.location.href = "/" )
}

export const convertDecimalToGbpStringInBrackets = (num, excludePlus) => {
    if (!num || num === 0.0)
        return ""
    else
        return "("+(!excludePlus?"+":"")+convertDecimalToGbp(num)+")"
}

export const convertDecimalToGbp = (num, alternativeForZero) => {
    if (typeof num === 'number' && num === 0.0)
        return alternativeForZero ? alternativeForZero : "Free"
    else if (!num)
        return ""
    else if (num < 1.0)
        return Math.floor(num * 100) +"p"
    else
        return "£" + Number(num).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export const convertDecimalToGbpOrEmpty = (num) => {
    if (num)
        return convertDecimalToGbp(num)
    else
        return ""
}

export const convert2dpOrNull = (n) => {
    if (typeof n !== 'number' && typeof n !== 'string')
        return "N/A"
    if (typeof n === 'string')
        n = Number(n.replace(/[^0-9.-]+/g,""))
    return to2dp(n)
}

export const to2dp = (n) => {
    if (typeof n !== 'number')
        return
    const rounded = Math.floor(n * 100.0) / 100.0
    // alert("before:"+n+", after:"+rounded)
    return rounded
}


export const convertToDoubleOrZero = (n) => {
    if (isNaN(n)) {
        if (typeof n === 'string')
            return Number(n.replace(/[^0-9.-]+/g,""))
        else
            return 0.0
    } else {
        if (typeof n === 'number')
            return n
        else
            return 0.0
    }
}

export const generateShortRef = () => {
    return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0
        const v = c === 'x' ? r : ((r & 0x3) | 0x8)
        return v.toString(16)
    })
}

export const generateUuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0
        const v = c === 'x' ? r : ((r & 0x3) | 0x8)
        return v.toString(16)
    })
}

export const shortDaysOfWeek = ["Sun","Mon","Tue","Wed","Thur","Fri","Sat"]
export const daysOfWeek = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
export const dayOfWeekName = daysOfWeek[new Date().getDay()]
export const dayOfWeekCapitalisedName = dayOfWeekName.toUpperCase()
export const getDayOfWeek = (i) => daysOfWeek[i]
export const getShortDayOfWeek = (i) => shortDaysOfWeek[i]

export const calcDistanceBetweenInKm = (latitude1, longitude1, latitude2, longitude2) => {
    // alert("calc dist:"+latitude1+", "+latitude2)
    const dLat = deg2rad(latitude2-latitude1)
    const dLon = deg2rad(longitude2-longitude1)
    const a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(latitude1)) * Math.cos(deg2rad(latitude2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
    return 3959 * c // Radius of the earth (miles)
}

function deg2rad(deg) {
    return deg * (Math.PI/180)
}

export const fetchAvailability = (availabilities, availabilityRef) => {
    if (!availabilityRef)
        return ""
    // alert("av:"+JSON.stringify(availabilities)+", ref:"+availabilityRef)
    if (!availabilities || availabilities.length === 0) {
        alert("Error: No availabilities found")
        return
    }
    const matches = availabilities.filter(a => a.ref === availabilityRef)
    if (matches.length === 1)
        return matches[0]
    else
        alert("Error there should always be one availability for a ref, but found: "+matches.length+", ref:"+availabilityRef)
}

export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}

export const navigateToEstablishmentId = (setGlobalState, prefix, establishmentId) => {
    openBackdropSpinner(setGlobalState)
    makeFetch(setGlobalState, '/api/establishment-ref-by-id?establishmentId='+establishmentId, response => {
        window.location.href = prefix + response.ref
    })
}
export const loadEstablishmentById = (setEstablishment, setGlobalState, id, funcOnLoad) => {
    if (id) {
        openBackdropSpinner(setGlobalState)
        makeFetch(setGlobalState, '/api/establishment?establishmentId=' + id, establishment => {
                setEstablishment(establishment)
                closeBackdropSpinner(setGlobalState)
                if (funcOnLoad) {
                    funcOnLoad(establishment)
                }
            }
        )
    }
}
export const loadEstablishment = (setEstablishment, setGlobalState, ref, funcOnLoad) => {
    if (ref) {
        openBackdropSpinner(setGlobalState)
        makeFetch(setGlobalState, '/api/establishment?establishmentRef='+ref, establishment => {
                setEstablishment(establishment)
                closeBackdropSpinner(setGlobalState)
                if (funcOnLoad) {
                    funcOnLoad(establishment)
                }
            }
        )
    }
}
export const priceValidateOnChange = (original) => {
    if (!original)
        return
    else
        return original.replace(/[^0-9_.]+/g,'')
}
export const telephoneNumberOnChange = (original) => {
    return original.replace(/[^\d +]/g,'')
}
export const isValidEmail = (email) =>  {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(String(email).toLowerCase())
}

export const removeQueryParams = () => {
    window.history.replaceState(null, null, window.location.pathname)
}

export const numberToWord = (n) => {
    switch (n) {
        case 0: return "zero"
        case 1: return "one"
        case 2: return "two"
        case 3: return "three"
        case 4: return "four"
        case 5: return "five"
        case 6: return "six"
        case 7: return "seven"
        case 8: return "eight"
        case 9: return "nine"
        case 10: return "ten"
        case 11: return "eleven"
        case 12: return "twelve"
        case 13: return "thirteen"
        case 14: return "fourteen"
        case 15: return "fifteen"
        case 16: return "sixteen"
        case 17: return "seventeen"
        case 18: return "eighteen"
        case 19: return "nineteen"
        default: return n
    }
}

export const sanitiseName = (name) => {
    // THIS SHOULD MATCH SERVER SIDE SANITISE
    return name.toLowerCase().replaceAll(" ", "_").replaceAll(".", "_").replaceAll("-", "_").replaceAll("&", "").replaceAll("'", "").replaceAll("/", "").replaceAll("\\", "").replaceAll("__", "")
}

export const reverseSanitise = (v) => {
    if (v)
        return v.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    else
        return ""
}

export const isPhoneNumber = (v) => {
    const phoneRegex = /^(\(?(0|\+44)[1-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/
    return v.match(phoneRegex)
}

export const doNothing = (i) => {
    // Function to prevent react warnings
}


