import React, {useContext, useEffect, useState} from "react";
import TopRightMenu from "../../components/Navbars/TopRightMenu";
import SearchInput from "../../components/CustomInput/SearchInput";
import {Button, useMediaQuery} from "@mui/material";
import GlobalState, {openEstablishmentRegistrationModal, openLoginModal} from "../../functions/GlobalStateFunctions";
import {makePost} from "../../functions/GeneralFunctions";
import {getQueryParam} from "../../functions/UrlFunctions";

const ResetPasswordModal = React.lazy(() => import('../modals/login/ResetPasswordModal'))
const EmailConfirmedModal = React.lazy(() => import('../modals/login/EmailConfirmedModal'))

export default function Landing({currentUser, openModalType}) {

    const [, setGlobalState] = useContext(GlobalState)
    const [searchText, setSearchText] = useState("")
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false)
    const [emailConfirmedModalOpen, setEmailConfirmedModalOpen] = useState(false)

    useEffect(() => {
        if (openModalType === "resetPassword") {
            setResetPasswordModalOpen(true)
        } else if (openModalType === "emailConfirmed") {
            makePost(setGlobalState, "/api/confirm-email-address", JSON.stringify({token: getQueryParam("token")}), () => {
                setEmailConfirmedModalOpen(true)
            })
        } else if (getQueryParam("openModalType") === "login") {
            openLoginModal(setGlobalState)
        }
    }, [setGlobalState, openModalType])


    const textColor = "#3c4858"

    const dot = <span style={{ height: "6px", width: "6px", margin: "1px 6px", backgroundColor: "rgb(125,46,190)", borderRadius: "50%", display: "inline-block"}}></span>


    const isSmDownScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))

    function openEstablishmentRegistration() {
        openEstablishmentRegistrationModal(setGlobalState)
    }


    return (
        <div style={{backgroundColor: "white", minHeight: "100vh"}}>
            <div style={{textAlign: "center"}}>
                <Button variant={"text"} style={{color: "black"}} href={"/buy-domain"}>Menu.co.uk is for sale (Click Here)</Button>
            </div>


            <div style={{display: "flex"}}>
                <span onClick={() => window.location.href = "/ordering-and-booking"} style={{
                    color: textColor,
                    paddingTop: "5px",
                    paddingLeft: "15px",
                    fontSize: "0.8rem",
                    cursor: "pointer",
                    lineHeight: "normal",
                    fontWeight: "100"
                }}>
                    {
                        isSmDownScreen ? "For Restaurants" : "Ordering & Booking System"
                    }
                </span>
                <span style={{marginLeft: "auto"}}>
                    <TopRightMenu currentUser={currentUser} color={textColor}/>
                </span>
            </div>
            <div style={{marginTop: "150px"}}>
                <h1 style={{display: "flex", justifyContent: "center", marginBottom: "0px"}}>
                    <svg style={{width: "auto", height: "60px"}} xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" viewBox="0 0 24 24">
                        <title>Menu logo</title>
                        <g fill="rgb(125,46,190)">
                            <path d="M21 3L3 10.52v.98l6.82 2.65L12.48 21h.98L21 3z"></path>
                        </g>
                    </svg>
                    <div style={{color: textColor, fontSize: "1.5em", letterSpacing: "1.8px", marginLeft: "3px"}}>menu
                    </div>
                </h1>
                <div style={{
                    color: textColor,
                    textAlign: "center",
                    marginTop: "0px",
                    marginBottom: "30px",
                    letterSpacing: "1.4px"
                }}>order {dot} reserve {dot} subscribe
                </div>
                <SearchInput styleMode={"landing"} placeholder={" "} searchText={searchText}
                             setSearchText={setSearchText}/>

                <div style={{textAlign: "center", marginTop: "25px"}}>
                    <Button onClick={openEstablishmentRegistration} style={{
                        textTransform: "none",
                        fontWeight: 100,
                        color: textColor,
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center",
                        fontSize: "0.8rem",
                        cursor: "pointer"
                    }}>
                        Add your Menu (it's free)?
                    </Button>
                </div>
            </div>

            {
                isSmDownScreen ? <div style={{
                        display: "grid",
                        gap: "2px",
                        position: "fixed",
                        backgroundColor: "#f1f1f1",
                        bottom: 0,
                        width: "100%",
                        color: textColor,
                        fontSize: "0.7rem",
                        paddingLeft: "10px",
                        paddingTop: "2px",
                        paddingBottom: "2px"
                    }}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <a style={{color: textColor}} href={"/ordering-and-booking"}>Ordering & Booking</a>
                            <a style={{color: textColor}} href={"/partnerships"}>Partnerships</a>
                            <a style={{color: textColor, paddingRight: "15px"}} href={"/register"}>Restaurant
                                Registration</a>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <a style={{color: textColor}} href={"/privacy"}>Privacy</a>
                            <a style={{color: textColor}} href={"/pricing"}>Pricing</a>
                            <span style={{paddingRight: "15px"}}>&copy; {new Date().getFullYear() + " Menu-UK Ltd"}</span>
                        </div>
                    </div>
                    : <div style={{
                        display: "flex",
                        gap: "20px",
                        position: "fixed",
                        backgroundColor: "#f1f1f1",
                        bottom: 0,
                        width: "100%",
                        color: textColor,
                        fontSize: "0.7rem",
                        paddingLeft: "10px",
                        paddingTop: "2px",
                        paddingBottom: "2px"
                    }}>
                        <a style={{color: textColor}} href={"/ordering-and-booking"}>Ordering & Booking</a>
                        <a style={{color: textColor}} href={"/register"}>Restaurant Registration</a>
                        <a style={{color: textColor}} href={"/partnerships"}>Partnerships</a>
                        <a style={{color: textColor}} href={"/privacy"}>Privacy</a>
                        <a style={{color: textColor}} href={"/pricing"}>Pricing</a>
                        <span style={{
                            marginLeft: "auto",
                            paddingRight: "15px"
                        }}>&copy; {new Date().getFullYear() + " Menu-UK Ltd"}</span>
                    </div>
            }

            {resetPasswordModalOpen && <ResetPasswordModal setIsOpen={setResetPasswordModalOpen}/>}
            {emailConfirmedModalOpen && <EmailConfirmedModal setIsOpen={setEmailConfirmedModalOpen}/>}

        </div>
    )
}
