import React from "react"
import CardHeader from "../components/Card/CardHeader"
import CardBody from "../components/Card/CardBody"
import Card from "../components/Card/Card";
import {Dialog, IconButton, useMediaQuery} from "@mui/material";
import {Close} from "@mui/icons-material";
import {whiteColor} from "../assets/jss/material-dashboard-react";

export default function CustomSmallModal({setModalOpen, header1, header2, body, onClose, size, backgroundImageUrl}) {

    const isSmDownScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const isMdDownScreen = useMediaQuery(theme => theme.breakpoints.down("md"))

    if (size && (size !== "xs" && size !== "sm" &&  size !== "md" && size !== "lg")) {
        alert("ERROR, only 'sm', 'md' & 'lg' sizes are supported")
        return
    }

    const updatedSize = (size === "lg" && !isMdDownScreen) ? "lg" : (size === "md" && !isMdDownScreen) ? "md" : "sm"


    // const width = updatedSize === "lg" ? "1170px" : updatedSize === "md" ? "870px" : "570px"
    // const maxWidth = updatedSize === "lg" ? "93%" : updatedSize === "md" ? "90%" : "85%"
    const headerStyle = isSmDownScreen ? {margin: "0px", borderRadius: "0px"} : {position: "relative", width: "unset", padding:"15px", borderRadius: "0px"}
    const bodyStyle = isSmDownScreen ? {margin: "5px", marginTop:"20px", padding: 0} : {}

    return (
        <Dialog maxWidth={updatedSize} fullWidth fullScreen={isSmDownScreen} open={true} onClose={() => { if (onClose) onClose(); else setModalOpen(false) }} aria-label={header1+" modal"}>
                    <Card style={{margin:0, boxShadow:"unset"}}>
                        <CardHeader color="primary" style={headerStyle}>
                            <IconButton onClick={() => setModalOpen(false)} style={{float:"right"}}>
                                <Close sx={{color:"white", marginRight:"-8px"}} />
                            </IconButton>
                            <h3 style={{
                                color: whiteColor,
                                marginTop: "0px",
                                minHeight: "auto",
                                fontWeight: "300",
                                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                                marginBottom: "3px",
                                textDecoration: "none",
                            }}>{header1}</h3>
                            {header2 && <span style={{fontSize:"0.8rem", fontWeight:100}}>{ header2 }</span>}
                        </CardHeader>

                        <CardBody style={bodyStyle}>
                            { body }
                        </CardBody>
                    </Card>
        </Dialog>
  )
}
