import React, {useContext, useEffect, useState} from "react";
import {AddCircle, DeleteForeverOutlined, Help} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
    IconButton,
    TextField, Tooltip
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
    formatTimePeriod,
} from "../../../../functions/DateFunctions";
import {
    clone, generateUuid,
    makePost
} from "../../../../functions/GeneralFunctions";
import {
    fetchWindowsByRelevantServices
} from "../../../../functions/AvailabilityFunctions";
import CustomSmallModal from "../../../../layouts/CustomSmallModal";
import GlobalState, {openBackdropSpinner} from "../../../../functions/GlobalStateFunctions";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import {NarrowStyledTableCell, StyledTableCell, StyledTableRow} from "./GeneralAvailabilityTable";
import TableBody from "@mui/material/TableBody";

export default function ModalEditAvailability({establishment, setModalOpen, originalAvailability, availabilityWindowName, reloadEstablishment, triggerRender, setChangesSuccessfullySavedAlertOpen, overrideGroupName, fetchCurrentAvailabilityLine}) {

    const [, setGlobalState] = useContext(GlobalState)

    // const [isEditForGeneralAvailability, setIsEditForGeneralAvailability] = useState(null)
    const [errorMsg, setErrorMsg] = useState("")
    const [newFromTime, setNewFromTime] = useState("")
    const [newToTime, setNewToTime] = useState("")
    const [changesMade, setChangesMade] = useState(false)
    const [availability, setAvailability] = useState(false)
    const [availabilityLine, setAvailabilityLine] = useState(false)

    const [addWindowSectionOpen, setAddWindowSectionOpen] = useState(false)

    function revertAvailability() {
        console.log("Triggered Revert Availability")
        const newAvailability = clone(originalAvailability)
        setAvailability(newAvailability)
        setAvailabilityLine(fetchCurrentAvailabilityLine(newAvailability))
    }

    useEffect(() => {
        revertAvailability()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onAddDeliveryWindow = () => {
        setErrorMsg("")
        if (!newFromTime) {
            setErrorMsg("From date must not be empty")
            return
        }
        if (!newToTime) {
            setErrorMsg("To date must not be empty")
            return
        }
        const fromHour = newFromTime.split(":")[0]
        const fromMinute = newFromTime.split(":")[1]
        const toHour = newToTime.split(":")[0]
        const toMinute = newToTime.split(":")[1]
        if (parseInt(fromHour) > parseInt(toHour) || (parseInt(fromHour) === parseInt(toHour) && parseInt(fromMinute) >= parseInt(toMinute))) {
            setErrorMsg("The end of the delivery window must be after the start of the delivery window")
            return
        }
        fetchWindowsByRelevantServices(availabilityLine, availabilityWindowName).map(window =>
            window.push({id:generateUuid(), from:fromHour+":"+fromMinute+":00", to:toHour+":"+toMinute+":00"})
        )
        setNewFromTime("")
        setNewToTime("")
        setChangesMade(true)
        triggerRender()
    }

    const onDelete = (index) => {
        setChangesMade(true)
        fetchWindowsByRelevantServices(availabilityLine, availabilityWindowName).map(window => window.splice(index,1) )
        triggerRender()
    }

    const revertChanges = () => {
        // loadEstablishment(establishment.ref)
        revertAvailability()
        setChangesMade(false)
        setNewFromTime("")
        setNewToTime("")
        triggerRender()
    }

    const saveChanges = () => {
        if (newFromTime || newToTime) {
            if (!window.confirm("Looks like you were adding an availability window but did not confirm. Continue anyway (the additional availability will be ignored)?"))
                return
        }
        // alert(JSON.stringify(availability))
        openBackdropSpinner(setGlobalState)
        makePost(setGlobalState,
            "/api/update-availability",
            JSON.stringify({establishmentId: establishment.id, availability: availability}),
            _ => {
                setModalOpen(false)
                reloadEstablishment()
                setChangesSuccessfullySavedAlertOpen(true)
            },
            err => {
                alert(err)
            }
        )
    }

    return (
        !availabilityLine ? <div>Not Initialised</div> :
            <CustomSmallModal setModalOpen={setModalOpen} header1={"Edit " + availabilityWindowName + " Availability"} body={
                <div>
                  {
                      fetchWindowsByRelevantServices(availabilityLine, availabilityWindowName)[0].length === 0
                      ? <div>Updating all {overrideGroupName}'s: <b>Closed</b></div>
                      : <div>
                          <div>
                              <span>Updating availability windows for all {overrideGroupName}s:</span>
                              <Tooltip title={"Customers will expect delivery at any point within this delivery window"}>
                                  <IconButton style={{cursor:"help", float:"right"}}><Help /></IconButton>
                              </Tooltip>
                          </div>
                          <TableContainer>
                              <Table>
                                  <TableHead>
                                      <StyledTableRow>
                                          <NarrowStyledTableCell>From</NarrowStyledTableCell>
                                          <NarrowStyledTableCell>To</NarrowStyledTableCell>
                                          <NarrowStyledTableCell></NarrowStyledTableCell>
                                      </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                      {
                                          fetchWindowsByRelevantServices(availabilityLine, availabilityWindowName)[0].map ((window, index) =>
                                              <StyledTableRow key={formatTimePeriod(window.from, window.to)}>
                                                  <StyledTableCell><TextField variant={"standard"} type={"time"} fullWidth InputLabelProps={{ shrink: true }} size={"small"} value={window.from} onChange={e => {window.from=e.target.value; setChangesMade(true); triggerRender()}} /></StyledTableCell>
                                                  <StyledTableCell><TextField variant={"standard"} type={"time"} fullWidth InputLabelProps={{ shrink: true }} size={"small"} value={window.to} onChange={e => {window.to=e.target.value; setChangesMade(true); triggerRender()}} /></StyledTableCell>
                                                  <StyledTableCell sx={{textAlign:"center"}}><IconButton onClick={() => onDelete(index)}><DeleteForeverOutlined color={"error"} /></IconButton></StyledTableCell>
                                              </StyledTableRow>
                                          )
                                      }
                                  </TableBody>
                              </Table>
                          </TableContainer>
                      </div>
                  }
                  <br />
                  {
                      !addWindowSectionOpen &&
                      <Button onClick={() => setAddWindowSectionOpen(true)} startIcon={<AddCircle />}>Add</Button>
                  }
                  {
                      addWindowSectionOpen &&
                      <Grid container spacing={2}>
                          <Grid item xs={5}><TextField variant={"standard"} size={"small"} type={"time"} fullWidth label={"From"} InputLabelProps={{ shrink: true }} value={newFromTime} onChange={e => setNewFromTime(e.target.value)} /></Grid>
                          <Grid item xs={5}><TextField variant={"standard"} size={"small"} type={"time"} fullWidth label={"To"} InputLabelProps={{ shrink: true }} value={newToTime} onChange={e => setNewToTime(e.target.value)} /></Grid>
                          <Grid item xs={2}><IconButton size={"large"} color={"primary"} onClick={onAddDeliveryWindow}><AddCircle fontSize={"large"}/></IconButton></Grid>
                      </Grid>
                  }

                  <div style={{color:"red"}}>{errorMsg}</div>
                  <br />
                  <Grid container spacing={1}>
                      <Grid item xs={4} ><Button variant={"outlined"} fullWidth onClick={() => {revertChanges(); setModalOpen(false)}}>Close</Button></Grid>
                      <Grid item xs={4} ><Button disabled={!changesMade} variant={"outlined"} fullWidth onClick={revertChanges}>Revert</Button></Grid>
                      <Grid item xs={4} ><Button disabled={!changesMade} variant={"contained"} fullWidth onClick={saveChanges}>Save</Button></Grid>
                  </Grid>
                </div>
           } />
    )
}
