import React from "react"
import {blackColor, hexToRgb, whiteColor} from "../../assets/jss/material-dashboard-react";


export default function Card({ className, style, ...rest }) {

  return (
    <div style={{
        border: "0",
        marginBottom: "30px",
        marginTop: "30px",
        borderRadius: "6px",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        background: whiteColor,
        width: "100%",
        boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minWidth: "0",
        wordWrap: "break-word",
        fontSize: ".875rem",
          ...style,
    }} {...rest} />
  )
}
