import React, {Fragment, useState} from "react";
import {
    tableCellClasses
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {styled} from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import ModalEditAvailability from "./ModalEditAvailability";
import GeneralAvailabilityTableOperatingHours from "./GeneralAvailabilityTableOperatingHours";
import {
    AvailabilityWindowCoreServices,
    fetchAllAvailabilityLines,
    fetchAvailabilityLineByDayOfWeek
} from "../../../../functions/AvailabilityFunctions";
import {getDayOfWeek} from "../../../../functions/GeneralFunctions";

export const NarrowStyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        background: "rgba(101, 47, 142, 0.4)",
        color: theme.palette.common.white,
        padding: "7px",
    },
    [`&.${tableCellClasses.body}`]: {
        padding: "5px",
    },
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        background: "rgba(101, 47, 142, 0.4)",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        padding: "5px",
    },
}))
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

export default function GeneralAvailabilityTable({establishment, availability, reloadEstablishment, setChangesSuccessfullySavedAlertOpen}) {

    const [selectedAvailabilityLine, ] = useState(false)
    const [selectedServiceName, setSelectedServiceName] = useState(false)
    const [dayOfWeek, setDayOfWeek] = useState(null)
    const [selectedOverrideGroupName, setSelectedOverrideGroupName] = useState("")

    const [editAvailabilityModalOpen, setEditAvailabilityModalOpen] = useState(false)

    const [, setUnusedValueForRerender] = useState(0)
    function triggerRender() { setUnusedValueForRerender(value => value + 1) }


    const onDayOfWeekAvailabilityClick = (dayOfWeek, serviceName) => {
        if (!serviceName) {
            alert("ERROR: serviceName not defined")
            return
        }
        setDayOfWeek(dayOfWeek)
        setSelectedServiceName(serviceName)
        setSelectedOverrideGroupName(getDayOfWeek(dayOfWeek))
        setEditAvailabilityModalOpen(true)
    }

    const onBankHolidayAvailabilityClick = (serviceName) => {
        if (!serviceName) {
            alert("ERROR: BH serviceName not defined")
            return
        }
        setDayOfWeek(null)
        setSelectedServiceName(serviceName)
        setSelectedOverrideGroupName("Bank Holiday")
        setEditAvailabilityModalOpen(true)
    }

    const fetchCurrentAvailabilityLine = (availability) => {
        if (dayOfWeek == null)
            return availability.bankHolidayAvailability
        else
            return fetchAvailabilityLineByDayOfWeek(availability, dayOfWeek)
    }

    function buildRow(name, availability, availabilityLine, onClick) {
        return <StyledTableRow key={availabilityLine.ref}>
            <StyledTableCell>
                { name }
            </StyledTableCell>
            <StyledTableCell>
                <GeneralAvailabilityTableOperatingHours
                    availability={availability}
                    onAvailabilityClick={onClick}
                    establishment={establishment}
                    availabilityLine={availabilityLine}
                />
            </StyledTableCell>
        </StyledTableRow>

    }

    return (
      <Fragment>

                                  <TableContainer component={Paper}>
                                      <Table>
                                          <TableHead>
                                              <TableRow>
                                                  <StyledTableCell>Day</StyledTableCell>
                                                  <StyledTableCell>Availability</StyledTableCell>
                                              </TableRow>
                                          </TableHead>
                                          <TableBody>
                                              {
                                                  fetchAllAvailabilityLines(availability).map((availabilityLine, dowIndex) =>
                                                    buildRow(getDayOfWeek(dowIndex), availability, availabilityLine, (serviceName) => { onDayOfWeekAvailabilityClick(dowIndex, serviceName) })
                                                  )
                                              }
                                              {
                                                  buildRow("Bank Holidays", availability, availability.bankHolidayAvailability, (serviceName) => { onBankHolidayAvailabilityClick(serviceName) })
                                              }
                                          </TableBody>
                                      </Table>
                                  </TableContainer>


          {
              editAvailabilityModalOpen && <ModalEditAvailability availabilityWindowName={selectedServiceName} establishment={establishment} setModalOpen={setEditAvailabilityModalOpen} reloadEstablishment={reloadEstablishment} serviceName={selectedServiceName} originalAvailability={availability} availabilityLineRef={selectedAvailabilityLine.ref} triggerRender={triggerRender} setChangesSuccessfullySavedAlertOpen={setChangesSuccessfullySavedAlertOpen} overrideGroupName={selectedOverrideGroupName} fetchCurrentAvailabilityLine={ fetchCurrentAvailabilityLine } />
          }
      </Fragment>
    )
}
