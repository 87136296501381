export const getQueryParam = (variable) => {
    const query = window.location.search.replace("?", "")
    const args = query.split("&")
    for (let i = 0; i < args.length; i++) {
        const pair = args[i].split("=")
        if (pair[0] === variable){
            return pair[1]
        }
    }
    console.log("unable to fetch query param:"+variable+", urlSearch:"+query)
    return ""
}
export const fetchEstablishmentRefFromCurrentLocation = (location) => {
    const url = fetchPathRelativeFromCurrentLocation(location)
    const urlParts = url.split("/")
    if (urlParts[0])
        return "" //Unsure on the format of the url (because first part should be empty, as before /), so unable to derive ref
    if (urlParts.length === 4)
        return url
    if (urlParts.length === 5) {
        if (urlParts[1] === "order-management") {
            return url.replace("/order-management", "")
        } else if (urlParts[1] === "my-establishments") {
            return url.replace("/my-establishments", "")
        } else if (urlParts[1] === "reservation-management") {
            return url.replace("/reservation-management", "")
        } else if (urlParts[1] === "subscription-management") {
            return url.replace("/subscription-management", "")
        } else if (urlParts[1] === "crm") {
            return url.replace("/crm", "")
        }
    }
    return ""
}
export const fetchPathRelativeFromCurrentLocation = (location) => {
    if (!location)
        return ""
    return location.pathname.endsWith("/") ? location.pathname.slice(0, -1) : location.pathname;
}
export const fetchRegionDetails = (location) => {
    const ref = fetchPathRelativeFromCurrentLocation(location)
    const parts = ref.split("/")
    return {region: parts[1]?.toLowerCase(), area: parts[2]?.toLowerCase(), establishmentName: parts[3]?.toLowerCase()}
}
