import React from "react"
import {Route, Routes} from "react-router-dom"

import Footer from "components/Footer/Footer.js"

import {useMediaQuery} from "@mui/material";

const EstablishmentMenu = React.lazy(() => import('../views/menu/EstablishmentMenu'))
const PageNotFound = React.lazy(() => import('../views/contentPages/PageNotFound'))
const DashboardPage = React.lazy(() => import('../views/Dashboard'))
const RegisterInfoPage = React.lazy(() => import('../views/establishmentOwner/registerEstablishment/RegisterInfoPage'));
const FunctionalityListing = React.lazy(() => import('../views/contentPages/FunctionalityListing'));
const Partnerships = React.lazy(() => import('../views/contentPages/Partnerships'));
const Region = React.lazy(() => import('../views/Region'));
const Area = React.lazy(() => import('../views/Area'));
const OrderingAndBookingPage = React.lazy(() => import('../views/contentPages/orderingAndBookingPage/OrderingAndBookingPage'));
const ContentPagePizza = React.lazy(() => import('../views/contentPages/ContentPagePizza'));
const ContentPageCoeliac = React.lazy(() => import('../views/contentPages/allergy/ContentPageCoeliac'));
const Postcode = React.lazy(() => import('../views/Postcode'));
const OnlineOnly = React.lazy(() => import('../views/OnlineOnlyListing'));
const Pricing = React.lazy(() => import('../views/contentPages/Pricing'));
const Privacy = React.lazy(() => import('../views/contentPages/Privacy'));
const CRMHome = React.lazy(() => import("../views/crm/CRMHome"));
const ContentPageAllergy = React.lazy(() => import("../views/contentPages/allergy/ContentPageAllergy"));
const ContentPageAbout = React.lazy(() => import("../views/contentPages/ContentPageAbout"));
const HelpPageRoot = React.lazy(() => import("../views/help/HelpPageRoot"));

export default function StandardLayout({ currentUser, setCurrentUser }) {
  const isSmDownScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))
  return (
      <React.Fragment>
      {
          <div style={{position: "relative", float: "right", maxHeight: "100%", width: "100%"}}>
            <div style={{padding: isSmDownScreen ? "30px 0px" : "30px 15px", marginTop: "60px", minHeight: "calc(100vh - 123px)"}}>
              <Routes>

                  {/*<Route path={"/"} element={<OrderingAndBookingPage currentUser={currentUser} />} />*/}
                  <Route path={"/ordering-and-booking"} element={<OrderingAndBookingPage currentUser={currentUser} />} />
                  <Route path={"/help/*"} element={<HelpPageRoot currentUser={currentUser} />} />
                  <Route path={"/near-me"} element={<DashboardPage currentUser={currentUser} />} />
                  <Route path={"/register"} element={<RegisterInfoPage currentUser={currentUser} />} />
                  <Route path={"/pricing"} element={<Pricing currentUser={currentUser} />} />
                  <Route path={"/functionality-listing"} element={<FunctionalityListing currentUser={currentUser} />} />
                  <Route path={"/partnerships"} element={<Partnerships currentUser={currentUser} />} />
                  <Route path={"/privacy"} element={<Privacy currentUser={currentUser} />} />
                  <Route path={"/pizza"} element={<ContentPagePizza currentUser={currentUser} />} />
                  <Route path={"/coeliac"} element={<ContentPageCoeliac currentUser={currentUser} />} />
                  <Route path={"/allergies"} element={<ContentPageAllergy currentUser={currentUser} />} />
                  <Route path={"/about"} element={<ContentPageAbout currentUser={currentUser} />} />
                  <Route path={"/postcode/:a"} element={<Postcode currentUser={currentUser} />} />
                  <Route path={"/online-only/:a"} element={<OnlineOnly currentUser={currentUser} />} />
                  <Route path={"/crm/*"} element={<CRMHome currentUser={currentUser} />} />

                  <Route path={"/:a/:b/:c"} element={<EstablishmentMenu currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
                  <Route path={"/:a/:b"} element={<Area currentUser={currentUser} />} />
                  <Route path={"/:a"} element={<Region currentUser={currentUser} />} />

                  <Route path="/*" element={<PageNotFound currentUser={currentUser} />} />
                </Routes>
            </div>
            <Footer/>
          </div>
      }
      </React.Fragment>
  )
}
