import React, {useContext, useRef, useState} from "react";
import {
    Button, Checkbox,
    Dialog,
    FormControl, FormControlLabel, FormGroup,
    FormLabel, Grid, IconButton, Radio,
    RadioGroup,
    Step,
    StepLabel,
    Stepper,
    TextField, useMediaQuery
} from "@mui/material";
import Paper from "@mui/material/Paper";
import GlobalState, {
    openBackdropSpinner
} from "../../../functions/GlobalStateFunctions";
import {
    ArrowBackIosNew,
    ArrowForwardIos,
    CloudUpload, DeleteForever, Save,
    Send, TipsAndUpdates,
} from "@mui/icons-material";
import SimplifiedAvailabilityTable from "./SimplifiedAvailabilityTable";
import {
    convertBusinessTypeEnumToPretty,
    makePost,
    makePostWithFile
} from "../../../functions/GeneralFunctions";
import {tomorrowPrettyString} from "../../../functions/DateFunctions";

export default function RegisterEstablishmentModal({selectedEstablishment, closeModal, currentUser}) {

    const [, setGlobalState] = useContext(GlobalState)

    const [stage, setStage] = useState(0)
    const [saveForLaterSelected, setSaveForLaterSelected] = useState(false)

    const uploadImageRef = useRef(null)
    const [menuImages, setMenuImages] = useState([])
    const [servicesToEnable, setServicesToEnable] = useState([])
    const [establishmentType, setEstablishmentType] = useState(selectedEstablishment ? selectedEstablishment.businessTypeEnum : "")
    const [establishmentName, setEstablishmentName] = useState(selectedEstablishment ? selectedEstablishment.name : "")
    const [establishmentAddress, setEstablishmentAddress] = useState((selectedEstablishment && selectedEstablishment.location) ? selectedEstablishment.location?.fullAddress : "")
    const [establishmentPostcode, setEstablishmentPostcode] = useState((selectedEstablishment && selectedEstablishment.location) ? selectedEstablishment.location?.postcode : "")
    const [openingHoursByDayOfWeek, ] = useState({ "monday": [], "tuesday": [], "wednesday": [], "thursday": [], "friday": [], "saturday": [], "sunday": [], "bankHolidays": [] })
    const [requestedMethodToImportMenu, setRequestedMethodToImportMenu] = useState("")
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [hasPermissionSelected, setHasPermissionSelected] = useState(false)

    const [, setUnusedValueForRerender] = useState(0)
    function triggerRender() { setUnusedValueForRerender(value => value + 1) }

    const [confirmationEmailSent, setConfirmationEmailSent] = useState(false)

    const establishmentNameLabel = (establishmentType === "Other") ? "Business Name" : convertBusinessTypeEnumToPretty(establishmentType, true) + " Name"

    const isSmDownScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))

    const standardTopMargin = "20px"
    const standardSmallTopMargin = "10px"

    function buildTipsSection(explanation) {
        return <div style={{display: "flex", backgroundColor:"#eeeeee", border: "grey solid thin", borderRadius: "10px", padding: "10px"}}>
            <TipsAndUpdates style={{alignSelf: "center", marginTop: "-2px"}} color={"warning"} />
            <div style={{fontStyle: "italic", color: "grey", paddingLeft: "10px", fontSize: "0.8rem"}}><b>Did you know?</b> {explanation}</div>
        </div>
    }

    function buildDetailsStage() {
        return <div>
            { buildTipsSection(<div>Menu is free: no setup fees, no subscription fees, no commission & no customer fees! We only have credit card fees and advertising fees (both optional).</div>) }
            {
                !selectedEstablishment && <FormControl style={{marginTop: standardTopMargin, width: "100%"}}>
                    <FormLabel>Are you registering:</FormLabel>
                    <RadioGroup value={establishmentType} onChange={e => setEstablishmentType(e.target.value)} row style={{justifyContent: "center"}}>
                        <FormControlLabel value="Restaurant" control={<Radio />} label="Restaurant" />
                        <FormControlLabel value="Takeaway" control={<Radio />} label="Takeaway" />
                        <FormControlLabel value="Cafe" control={<Radio />} label="Cafe" />
                        <FormControlLabel value="Other" control={<Radio />} label="Other" />
                    </RadioGroup>
                </FormControl>
            }
            { establishmentType && <TextField style={{marginTop:standardTopMargin}} fullWidth label={establishmentNameLabel} value={establishmentName} onChange={e => setEstablishmentName(e.target.value)} /> }
            { establishmentType && <TextField style={{marginTop:standardSmallTopMargin}} fullWidth label={"Address"} value={establishmentAddress} onChange={e => setEstablishmentAddress(e.target.value)} /> }
            { establishmentType && <TextField style={{marginTop:standardSmallTopMargin}} fullWidth label={"Postcode"} value={establishmentPostcode} onChange={e => setEstablishmentPostcode(e.target.value)} /> }
        </div>
    }
    function buildOpeningHoursStage() {
        return <div>
            { buildTipsSection(<div>You will have 24-7 access to the control panel where you can set schedules for each of the services you offer!</div>) }
            <br/>
            <SimplifiedAvailabilityTable openingHoursByDayOfWeek={openingHoursByDayOfWeek} />
        </div>
    }
    function buildMenusStage() {
        return <div>
            <FormControl>
                <FormLabel>How would you like to add your menu items:</FormLabel>
                <RadioGroup style={{marginLeft: isSmDownScreen ? "0px" : "20px"}} value={requestedMethodToImportMenu} onChange={e => setRequestedMethodToImportMenu(e.target.value)}>
                    <FormControlLabel value="Manual" control={<Radio />} label="Manually type in the details" />
                    <FormControlLabel value="LoadFromImage" control={<Radio />} label="Automatically load from images" />
                    <FormControlLabel value="Skip" control={<Radio />} label="Skip. I will do this later!" />
                </RadioGroup>
            </FormControl>
            {
                requestedMethodToImportMenu === "LoadFromImage" &&
                <div style={{marginTop: "15px"}}>
                    {
                        menuImages.map(i =>
                            <div style={{display: "flex", alignItems: 'center', flexWrap: 'wrap',}} key={i.name}>
                                <IconButton style={{marginLeft: "10px"}} onClick={() => setMenuImages(menuImages.filter(i2=>i2.name !== i.name))} size={"small"} color={"error"}><DeleteForever/></IconButton>
                                <span>{i.name}</span>
                            </div>)
                    }
                    <Button startIcon={<CloudUpload />} style={{marginTop: "15px"}} variant={"contained"} fullWidth onClick={() => uploadImageRef.current.click()}>{ menuImages.length === 0 ? "Upload Image of the Menu" : "Upload Another Image" }</Button>
                    <input accept=".*" type={"file"} onChange={e => { menuImages.push(e.target.files[0]); triggerRender()}}  ref={uploadImageRef} style={{display:"none"}} />
                </div>

            }
        </div>
    }
    function toggleServicesToEnable(name) {
        let services = servicesToEnable
        if (servicesToEnable.includes(name))
            services = services.filter(s => s !== name)
        else
            services.push(name)
        setServicesToEnable(services)
        triggerRender()
    }
    function buildReviewStage() {
        return <div>
            { buildTipsSection("Service configuration can be changed at any time in your control panel") }
            <FormControl style={{marginTop: standardTopMargin}}>
                <FormLabel>Which services do you want to enable:</FormLabel>
                <FormControlLabel style={{marginLeft: "20px"}} control={ <Checkbox checked={servicesToEnable.includes("Ordering")} onChange={() => { toggleServicesToEnable("Ordering") }} name="Ordering" /> } label="Ordering" />
                <FormControlLabel style={{marginLeft: "20px"}} control={ <Checkbox checked={servicesToEnable.includes("Subscriptions")} onChange={() => { toggleServicesToEnable("Subscriptions") }} name="Subscriptions" /> } label="Subscriptions" />
                <FormControlLabel style={{marginLeft: "20px"}} control={ <Checkbox checked={servicesToEnable.includes("Reservations")} onChange={() => {toggleServicesToEnable("Reservations") }} name="Reservations" /> } label="Reservations" />
            </FormControl>
        </div>
    }
    function buildConfirmEmailStage() {
        return <div>
            <h3 style={{marginTop: "-10px"}}>{saveForLaterSelected ? <span>Save your progress</span> : <span>Nearly there ...</span>}</h3>
            {
                confirmationEmailSent
                ? <div>
                    {/*<h4><IconButton><ForwardToInbox /></IconButton>An email is on the way</h4>*/}
                    {/*<div>Please follow the instructions in the email to complete the setup process.</div>*/}
                    <b>Your Registration has been Submitted</b>
                    <div style={{fontStyle: "italic", color: "grey", fontSize: "0.8rem"}}>We will contact you at: {email}</div>
                    <div style={{marginTop: "20px"}}>You will receive an email by the end of {tomorrowPrettyString()} with access details to your Menu.co.uk control panel.</div>
                    <Button style={{marginTop: standardTopMargin}} size={"large"} fullWidth variant={"text"} onClick={closeModal}>Close</Button>
                </div>
                : <div>
                    {/*<div style={{fontSize: "0.9rem", fontStyle: "italic"}}>Your details</div>*/}

                        <Grid container spacing={1} style={{marginTop: standardTopMargin}}>
                            <Grid item xs={6}>
                                <TextField fullWidth label={"First Name"} value={firstName} onChange={e => setFirstName(e.target.value)} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label={"Last Name"} value={lastName} onChange={e => setLastName(e.target.value)} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label={"Email"} type={"email"} value={email} onChange={e => setEmail(e.target.value)} />
                            </Grid>
                        </Grid>



                        <FormGroup style={{marginTop: "10px", marginLeft: "10px"}}>
                        <FormControlLabel style={{color:"black"}}
                                          control={<Checkbox checked={hasPermissionSelected} onChange={e => setHasPermissionSelected(!hasPermissionSelected)} />}
                                          label={"I own, manage or otherwise have permission to trade on behalf of "+establishmentName} />
                    </FormGroup>
                    <Button style={{marginTop: standardTopMargin}} size={"large"} fullWidth variant={"contained"} disabled={!email || !hasPermissionSelected} startIcon={saveForLaterSelected ? <Save /> : <Send />} onClick={onSubmitOrSaveForLater} >{saveForLaterSelected ? "Save" : "Send Verification Email"}</Button>
                </div>
            }
        </div>
    }
    function buildStageBody() {
         switch (stage) {
             case 0: return buildDetailsStage()
             case 1: return buildOpeningHoursStage()
             case 2: return buildMenusStage()
             case 3: return buildReviewStage()
             case 4: return buildConfirmEmailStage()
             default: return <div>Unknown Stage</div>
        }
    }
    function validateStage() {
        switch (stage) {
            case 0: return establishmentName && establishmentAddress
            case 1: return true
            case 2: return requestedMethodToImportMenu === "Manual" || (requestedMethodToImportMenu === "LoadFromImage" && true) || requestedMethodToImportMenu === "Skip"
            case 3: return true
            case 4: return true
            default: return true
        }
    }
    function buildStepper() {
        if (stage >= 4)
            return
        return <Stepper activeStep={stage} alternativeLabel>
            <Step><StepLabel>Your Details</StepLabel></Step>
            <Step><StepLabel>Opening Hours</StepLabel></Step>
            <Step><StepLabel>Your Menu</StepLabel></Step>
            <Step><StepLabel>Enable Services</StepLabel></Step>
        </Stepper>
    }
    function onSubmitOrSaveForLater() {
        const json = JSON.stringify({
            existingEstablishmentId: selectedEstablishment?.id,
            saveForLaterSelected: saveForLaterSelected,
            establishmentName: establishmentName,
            establishmentAddress: establishmentAddress,
            establishmentPostcode: establishmentPostcode,
            openingHoursByDayOfWeek: openingHoursByDayOfWeek,
            servicesToEnable: servicesToEnable,
            requestedMethodToImportMenu: requestedMethodToImportMenu,
            firstName: firstName,
            lastName: lastName,
            email: email,
        })
        openBackdropSpinner(setGlobalState)
        makePost(setGlobalState, '/api/on-register-establishment-submission', json,
            response => {
            const registrationId = response.payload
            setConfirmationEmailSent(true)

            menuImages.forEach(img => {
                const formData  = new FormData()
                formData.append("file", img)
                formData.append("filename", img.name)
                formData.append("registrationId", registrationId)

                makePostWithFile(setGlobalState, "/api/on-register-establishment-submission-menu-image", formData,_ => {
                    console.log("Uploaded menu image: "+img.name)
                } )
            })
        })
    }
    function onSaveAndContinueLaterClick() {
        setStage(4)
        setSaveForLaterSelected(true)
    }
    function onNextButtonClick() {
        const msg = "<div>Stage: "+ (stage+1)
            + (servicesToEnable.length > 0 ? "</div><div>Services To Enable: " + servicesToEnable : "")
            + (establishmentType ? "</div><div>Establishment Type: " + establishmentType : "")
            + (selectedEstablishment?.id ? "</div><div>Selected Establishment: " +selectedEstablishment?.id : "")
            + (establishmentName ? "</div><div>Establishment Name: " + establishmentName : "")
            + (establishmentAddress ? "</div><div>Establishment Address: " + establishmentAddress : "")
            + (establishmentPostcode ?"</div><div>Establishment Postcode: " + establishmentPostcode : "")
            + (openingHoursByDayOfWeek ? "</div><div>opening Hours By Day Of Week: " + JSON.stringify(openingHoursByDayOfWeek) : "")
            + (requestedMethodToImportMenu ? "</div><div>Requested Method To Import Menu: "+ requestedMethodToImportMenu : "")
            + (firstName ? "</div><div>First Name: " + firstName : "")
            + (lastName ? "</div><div>Last Name: " + lastName : "")
            + (email ? "</div><div>Email: " + email : "")
            + "</div>"

        makePost(setGlobalState, '/api/on-register-establishment-page-change',
            JSON.stringify({ msg: msg }),
            _ => { }
        )
        setStage(stage + 1)
    }

    function buildNavigationButtons() {
        if (stage >= 4)
            return
        return <div>
            <div style={{display: "flex"}}>
                { stage === 0 && <Button style={{paddingBottom: 0, paddingTop: 0, paddingLeft: "15px", color: "grey"}} onClick={() => closeModal()}>Cancel</Button> }
                { stage > 0 && <Button style={{paddingBottom: 0, paddingTop: 0, paddingLeft: "15px"}} startIcon={<ArrowBackIosNew />} onClick={() => setStage(stage - 1)}>Back</Button> }
                <Button disabled={!validateStage()} endIcon={<ArrowForwardIos />} style={{marginLeft: "auto", paddingBottom: 0, paddingTop: 0, paddingRight: "15px"}} onClick={onNextButtonClick}>Next</Button>
            </div>
            {
                stage > 0 && <div style={{display: "flex"}}>
                    <Button style={{marginLeft: "auto", marginRight: "auto", marginTop: "10px", paddingBottom: 0, paddingTop: 0}} startIcon={<CloudUpload />} onClick={onSaveAndContinueLaterClick}>Save & Continue Later</Button>
                </div>
            }
        </div>
    }

    return (
        <Dialog fullScreen={false} scroll={"body"} fullWidth={true} maxWidth={"sm"} open={true} onClose={closeModal}>
            <Paper style={{paddingTop: "20px", paddingBottom: "20px"}}>
                {buildStepper()}
                <div style={{padding: "30px 15px"}}>
                    {buildStageBody()}
                </div>
                {buildNavigationButtons()}
            </Paper>
        </Dialog>
    )
}
