import React, {Fragment} from "react";
import {Edit} from "@mui/icons-material";
import {
    Chip
} from "@mui/material";
import {timeRangeString} from "../../../../functions/DateFunctions";
import {ServiceEnumTableBooking} from "../../../../functions/GeneralFunctions";
import {
    AvailabilityWindowCoreServices,
    AvailabilityWindowRepeatCollection, AvailabilityWindowRepeatDelivery,
    AvailabilityWindowTableBooking, AvailabilityWindowTakeawayCollection, AvailabilityWindowTakeawayDelivery
} from "../../../../functions/AvailabilityFunctions";

export default function GeneralAvailabilityTableOperatingHours({establishment, availability, availabilityLine, onAvailabilityClick}) {

    return (
        <Fragment>
            {
                availability.differentAvailabilityForEachService
                    ? <Fragment>
                        {
                            establishment.takeawaysDetails.collectionDetails.enabled &&
                            <Chip
                                  label={timeRangeString(availabilityLine.takeawayCollection)} color={"primary"}
                                  deleteIcon={<Edit/>}
                                  onDelete={() => onAvailabilityClick(AvailabilityWindowTakeawayCollection)}
                                  onClick={() => onAvailabilityClick(AvailabilityWindowTakeawayCollection)}/>
                        }
                        {
                            establishment.takeawaysDetails.deliveryDetails.enabled &&
                            <Chip style={{marginLeft:"5px"}}
                                  label={timeRangeString(availabilityLine.takeawayDelivery)} color={"info"}
                                  deleteIcon={<Edit/>}
                                  onDelete={() => onAvailabilityClick(AvailabilityWindowTakeawayDelivery)}
                                  onClick={() => onAvailabilityClick(AvailabilityWindowTakeawayDelivery)}/>
                        }
                        {
                            establishment.repeatOrdersDetails.collectionDetails.enabled &&
                            <Chip style={{marginLeft:"5px"}}
                                  label={timeRangeString(availabilityLine.repeatCollectionWindows)} color={"default"}
                                  deleteIcon={<Edit/>}
                                  onDelete={() => onAvailabilityClick(AvailabilityWindowRepeatCollection)}
                                  onClick={() => onAvailabilityClick(AvailabilityWindowRepeatCollection)}/>
                        }
                        {
                            establishment.eatInServiceDetails.enabled &&
                            <Chip style={{marginLeft:"5px"}}
                                  label={timeRangeString(availabilityLine.tableBooking)} color={"warning"}
                                  deleteIcon={<Edit/>}
                                  onDelete={() => onAvailabilityClick(AvailabilityWindowTableBooking)}
                                  onClick={() => onAvailabilityClick(AvailabilityWindowTableBooking)}/>
                        }
                    </Fragment>
                    // Choose one -> because all services must have the same availability
                    : <Fragment>
                        <Chip label={timeRangeString(availabilityLine.takeawayCollection)} deleteIcon={<Edit/>}
                              onDelete={() => onAvailabilityClick(AvailabilityWindowCoreServices)}
                              onClick={() => onAvailabilityClick(AvailabilityWindowCoreServices)}/>
                    </Fragment>
            }
            {
                establishment.repeatOrdersDetails.deliveryDetails.enabled &&
                <Chip label={timeRangeString(availabilityLine.repeatDeliveryWindows)} color={"error"}
                      deleteIcon={<Edit/>}
                      onDelete={() => onAvailabilityClick(AvailabilityWindowRepeatDelivery)}
                      onClick={() => onAvailabilityClick(AvailabilityWindowRepeatDelivery)}/>
            }
        </Fragment>
    )
}
