import React, {useEffect} from "react";
import LoadingMessage from "../../components/LoadingMessage";

export default function RedirectToWebmail() {

    useEffect(() => {
        window.location.replace('https://webmail.mail.eu-west-1.awsapps.com/workmail/?organization=menu-uk')
    }, [])

    return (
        <LoadingMessage msg={"Redirecting to Webmail"} />
  )
}
