import React, {Fragment} from "react"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Hidden from "@mui/material/Hidden"
import Popper from "@mui/material/Popper"
import Notifications from "@mui/icons-material/Notifications"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import {red} from "@mui/material/colors"
import SearchInput from "../CustomInput/SearchInput"
import {IconButton} from "@mui/material";
import {styled} from "@mui/material/styles";
import {
  defaultFont,
  grayColor,
  primaryBoxShadow,
  primaryColor,
  whiteColor
} from "../../assets/jss/material-dashboard-react";
import TopRightMenu from "./TopRightMenu";


export const StyledDropdownItem = styled(MenuItem) ({
  ...defaultFont,
  fontSize: "13px",
  padding: "5px 10px",
  margin: "0 5px",
  borderRadius: "2px",
  WebkitTransition: "all 150ms linear",
  MozTransition: "all 150ms linear",
  OTransition: "all 150ms linear",
  MsTransition: "all 150ms linear",
  transition: "all 150ms linear",
  display: "flex",
  clear: "both",
  fontWeight: "400",
  lineHeight: "1.42857143",
  color: grayColor[8],
  whiteSpace: "nowrap",
  height: "unset",
  minHeight: "unset",
  "&:hover": {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...primaryBoxShadow,
  },
})

export default function NavbarLinks({currentUser, isSearchEnabled, searchText, setSearchText, searchPlaceholder}) {
  const [notificationMenuAnchorAndOpen, setNotificationMenuAnchorAndOpen] = React.useState(null)

  const handleClickNotification = (event) => {
    if (notificationMenuAnchorAndOpen && notificationMenuAnchorAndOpen.contains(event.target)) {
      setNotificationMenuAnchorAndOpen(null)
    } else {
      setNotificationMenuAnchorAndOpen(event.currentTarget)
    }
  }
  const handleCloseNotification = () => {
    setNotificationMenuAnchorAndOpen(null)
  }

  return (
      <Fragment>
    <div>
      <Hidden mdDown>
        { isSearchEnabled && <SearchInput placeholder={searchPlaceholder} searchText={searchText} setSearchText={setSearchText} /> }
        {
        currentUser && currentUser.loggedIn &&
        <div style={{color: "white", display: "inline-block"}}>
          <IconButton  aria-owns={notificationMenuAnchorAndOpen ? "notification-menu-list-grow" : null} aria-haspopup="true" onClick={handleClickNotification}>
            <Notifications style={{color:"white"}} />
            {
              currentUser?.notificationsCount > 0 &&
              <span>{currentUser?.notificationsCount}</span>
            }
          </IconButton>
          <Popper style={{zIndex:4000}} open={Boolean(notificationMenuAnchorAndOpen)} anchorEl={notificationMenuAnchorAndOpen} transition >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} id="notification-menu-list-grow" style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom", }} >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseNotification}>
                    <MenuList role="menu">
                      {
                        currentUser.notifications.map(function (notification, index) {
                          return <StyledDropdownItem key={"n"+index+notification.msg} onClick={handleCloseNotification}><span style={{verticalAlign:"middle"}}>{notification.msg}</span> <DeleteForeverIcon style={{color: red[300], paddingLeft:"10px", marginLeft:"auto"}}/></StyledDropdownItem>
                        })
                      }
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        }
      </Hidden>
      <TopRightMenu currentUser={currentUser} />
    </div>
      </Fragment>
  )
}
