import React, {Fragment, useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import {daysOfWeek} from "../../../functions/GeneralFunctions";
import SimplifiedEditAvailability, {fetchOpeningWindows} from "./SimplifiedEditAvailability";
import {
    StyledTableCell,
    StyledTableRow
} from "../../establishmentOwner/establishmentsCrud/availability/GeneralAvailabilityTable";
import {timeRangeString} from "../../../functions/DateFunctions";
import {Edit} from "@mui/icons-material";
import {Chip} from "@mui/material";

export default function SimplifiedAvailabilityTable({openingHoursByDayOfWeek}) {

    const [dowToEdit, setDowToEdit] = useState(undefined)

    const [editAvailabilityModalOpen, setEditAvailabilityModalOpen] = useState(false)

    const [, setUnusedValueForRerender] = useState(0)
    function triggerRender() {
        setUnusedValueForRerender(value => value + 1)
    }


    const onDayOfWeekAvailabilityClick = (dayOfWeek) => {
        setDowToEdit(dayOfWeek)
        setEditAvailabilityModalOpen(true)
    }

    function buildRow(dowName, onClick) {
        const openingWindows = fetchOpeningWindows(openingHoursByDayOfWeek, dowName)
        return <StyledTableRow key={dowName}>
            <StyledTableCell>
                {dowName}
            </StyledTableCell>
            <StyledTableCell>
                <Chip label={timeRangeString(openingWindows)} deleteIcon={<Edit/>} onDelete={onClick} onClick={onClick}/>  {/*OnDelete is just the edit icon*/}
            </StyledTableCell>
        </StyledTableRow>

    }

    return (
        <Fragment>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{paddingTop:"2px", paddingBottom: "2px"}}>Day</StyledTableCell>
                            <StyledTableCell style={{paddingTop:"2px", paddingBottom: "2px"}}>Opening Hours</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            daysOfWeek.map( dow =>
                                buildRow(dow, () => { onDayOfWeekAvailabilityClick(dow) })
                            )
                        }
                        {
                            buildRow("Bank Holidays", () => { onDayOfWeekAvailabilityClick("Bank Holidays") })
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {
                editAvailabilityModalOpen && <SimplifiedEditAvailability setModalOpen={setEditAvailabilityModalOpen} triggerRender={triggerRender} name={dowToEdit} openingHoursByDayOfWeek={openingHoursByDayOfWeek} />
            }
        </Fragment>
    )
}
