import React from "react";
import {
  dangerColor,
  infoColor, primaryColor, roseColor,
  successColor,
  warningColor,
} from "../../assets/jss/material-dashboard-react";

export default function CardHeader({ headerVertPadding, color, style, ...rest }) {

  let color1, color2
  switch(color) {
    case "warning": color1=warningColor[0]; color2=warningColor[1]; break;
    case "success": color1=successColor[0]; color2=successColor[1]; break;
    case "danger": color1=dangerColor[0]; color2=dangerColor[1]; break;
    case "info": color1=infoColor[0]; color2=infoColor[1]; break;
    case "primary": color1=primaryColor[0]; color2=primaryColor[1]; break;
    case "rose": color1=roseColor[0]; color2=roseColor[1]; break;
    default: color1=successColor[0]; color2=successColor[1]; break;
  }
  return (

    <div
        style={{
          color: "white",
          background: "linear-gradient(60deg, " + color1 + ", " + color2 + ")",
          paddingTop: "0.75rem",
          paddingBottom: "0.75rem",
          paddingLeft: "1.25rem",
          paddingRight: "1.25rem",
          marginBottom: "0",
          borderBottom: "none",
          boxSizing: "content-box",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          zIndex: "3",
          ...style,
        }}
        {...rest} />
  );
}

