import {getDayOfWeek, getShortDayOfWeek} from "./GeneralFunctions";

// MUST MATCH SERVER
const upcomingBankHolidays = ["2022-12-26","2022-12-27","2023-01-02","2023-04-07","2023-04-10","2023-05-01","2023-05-08","2023-05-29","2023-08-28","2023-12-25","2023-12-26"]

const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
const longMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
export function convertServerLocalDateTimeToSimpleDateTime(d, textIfUndefined) {
    if (!d)
        return textIfUndefined
    const date = new Date(d)
    let datePartString = (isToday(date)) ? "Today" : date.getDate() + " " + shortMonths[date.getMonth()]
    return datePartString + ", " + numberAs2DigitString(date.getHours()) +":" + numberAs2DigitString(date.getMinutes())
}
export const getMonthName = (i) => longMonths[i]
export const getShortMonthName = (i) => shortMonths[i]
export const calcIsBankHoliday = (date) => upcomingBankHolidays.includes(date)


export const formatTimePeriod = (from, to) => {
    if (!from || ! to)
        return
    return parseJsonTimeToSimpleTimeString(from) + " - " + parseJsonTimeToSimpleTimeString(to)
}
export const timeRangeString = (timeRanges) => {
    if (!timeRanges || !timeRanges.length || timeRanges.length === 0)
        return "Closed"
    return timeRanges.reduce((total, times) => total + (total.length > 0 ? ", " : "") + formatTimePeriod(times.from, times.to), "")
}
export const today = () => {
    return new Date()
}
export const tomorrow = () => {
    const d = new Date()
    d.setDate(d.getDate() + 1)
    return d
}
export const yesterday = () => {
    const d = new Date()
    d.setDate(d.getDate() - 1)
    return d
}
export const todayString = () => {
    return convertToServerLocalDateString(today())
}
export const todayPrettyString = () => {
    return convertToPrettyDate(today())
}
export const yesterdayPrettyString = () => {
    return convertToPrettyDate(yesterday())
}
export const todayDateTimeString = () => {
    return convertToServerLocalDateTimeString(today())
}
export const tomorrowPrettyString = () => {
    return convertToPrettyDate(tomorrow())
}
export const tomorrowString = () => {
    return convertToServerLocalDateString(tomorrow())
}
export const tomorrowDateTimeString = () => {
    return convertToServerLocalDateTimeString(tomorrow())
}
export const convertToServerLocalDateTimeString = (dt) => {
    return convertToServerLocalDateString(dt) + "T" + formatServerCompatibleTime(dt)+":00"
}

export const isWithinTwoHoursFromNow = (d) => {
    const twoHoursFromNow = new Date()
    twoHoursFromNow.setHours(twoHoursFromNow.getHours() + 2)
    return d < twoHoursFromNow
}
export const nowDateString = () => {
    const date = new Date()
    return convertToServerLocalDateString(date)
}
export const convertToServerLocalDateString = (date) => {
    const formattedDate = ""+date.getFullYear()+"-"+numberAs2DigitString(date.getMonth()+1)+"-"+numberAs2DigitString(date.getDate())
    // console.log("converted:"+date+", to:"+formattedDate)
    return formattedDate
}
function calculateDatePostfix(dayOfWeek) {
    if (dayOfWeek < 1)
        return ""
    switch (dayOfWeek) {
        case 1: return "st"
        case 2: return "nd"
        case 3: return "rd"
        case 4: return "th"
        case 5: return "th"
        case 6: return "th"
        case 7: return "th"
        case 8: return "th"
        case 9: return "th"
        case 10: return "th"
        case 11: return "th"
        case 12: return "th"
        case 13: return "th"
        case 14: return "th"
        case 15: return "th"
        case 16: return "th"
        case 17: return "th"
        case 18: return "th"
        case 19: return "th"
        case 20: return "th"
        case 21: return "st"
        case 22: return "nd"
        case 23: return "rd"
        case 24: return "th"
        case 25: return "th"
        case 26: return "th"
        case 27: return "th"
        case 28: return "th"
        case 29: return "th"
        case 30: return "th"
        case 31: return "st"
        default: return ""
    }
}
export const convertToPrettyDate = (date) => {
    if (!date)
        return
    const formattedDate = getDayOfWeek(date.getDay()) + ", " + date.getDate() + calculateDatePostfix(date.getDate()) + " " + getMonthName(date.getMonth()) + " " + date.getFullYear()
    // console.log("converted:"+date+", to:"+formattedDate)
    return formattedDate
}
export const convertToShortPrettyDate = (date) => {
    const formattedDate = getShortDayOfWeek(date.getDay()) + ", " + date.getDate() + calculateDatePostfix(date.getDate()) + " " + getShortMonthName(date.getMonth()) + " " + date.getFullYear()
    // console.log("converted:"+date+", to:"+formattedDate)
    return formattedDate
}
export const convertToVeryShortPrettyDate = (date) => {
    const formattedDate = date.getDate() + calculateDatePostfix(date.getDate()) + " " + getShortMonthName(date.getMonth()) + " " + date.getFullYear()
    // console.log("converted:"+date+", to:"+formattedDate)
    return formattedDate
}
export const convertToShortPrettyTime = (date) => {
    const hoursString = (date.getHours() < 10) ? ("0"+date.getHours()) : ""+date.getHours()
    const minutesString = (date.getMinutes() < 10) ? ("0"+date.getMinutes()) : ""+date.getMinutes()

    return hoursString + ":" + minutesString
}
export const isToday = (date) => convertToServerLocalDateString(date) === todayString()
export const isTomorrow = (date) => convertToServerLocalDateString(date) === tomorrowString()
export const convertToShortPrettyDateOrTodayString = (date) => {
    if (isToday(date))
        return "Today"
    else if (isTomorrow(date))
        return "Tomorrow"
    else
        return convertToShortPrettyDate(date)

}
export const convertServerDateToShortPrettyDateOrTodayString = (date) => {
    const d = parseJsonDateToDate(date)
    return convertToShortPrettyDateOrTodayString(d)
}
export const parseJsonDateToDate = (d) => {
    if (!d)
        return
    const parts = d.split("-")
    const dateTime = today()
    dateTime.setFullYear(parts[0])
    dateTime.setMonth(parseInt(parts[1])-1)
    dateTime.setDate(parts[2])
    return dateTime
}
export const parseJsonTimeToDate = (d) => {
    const parts = d.split(":")
    const dateTime = today()
    dateTime.setHours(parts[0])
    dateTime.setMinutes(parts[1])
    if (parts.length > 2)
        dateTime.setSeconds(parts[2])
    return dateTime
}
export const convertJsonDateTimeToPrettyString = (dtString) => {
    if (!dtString)
        return ""
    const dateTime = parseJsonDateTimeToDate(dtString)
    return convertToShortPrettyDate(dateTime) + ", " + convertToShortPrettyTime(dateTime)
}
export const parseJsonDateTimeToDate = (dt) => {
    const dateTimeParts = dt.split("T")
    return parseJsonDateAndTimeToDate(dateTimeParts[0], dateTimeParts[1])
}
export const parseJsonDateAndTimeToDate = (d, t) => {
    const dataParts = d.split("-")
    const dateTime = today()
    dateTime.setFullYear(dataParts[0])
    dateTime.setMonth(parseInt(dataParts[1])-1)
    dateTime.setDate(dataParts[2])

    const timeParts = t.split(":")
    dateTime.setHours(timeParts[0])
    dateTime.setMinutes(timeParts[1])
    if (timeParts.length > 2)
        dateTime.setSeconds(timeParts[2])

    return dateTime
}
export const areDatesSame = (d1, d2) => {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
}
const numberAs2DigitString = (mi) => {
    if (mi < 10)
        return "0"+mi
    else
        return mi
}
export const parseJsonTimeToSimpleTimeString = (d) => {
    const dateTimeObj = (d instanceof Date) ? d : parseJsonTimeToDate(d)
    return formatSimpleTime(dateTimeObj)
}

export const formatServerCompatibleTime = (t) => {
    const hours = (t.getHours() < 10) ? "0"+t.getHours() : t.getHours()
    return hours + ":" + numberAs2DigitString(t.getMinutes())
}

export const formatSimpleTime = (t) => {
    return t.getHours() + ":" + numberAs2DigitString(t.getMinutes())
}

function createSelectableDate(d, daysToAdd) {
    const newDate = new Date()
    newDate.setDate(d.getDate() + daysToAdd)
    const description = daysToAdd === 0 ? "Today" : (daysToAdd === 1 ? "Tomorrow" : convertToPrettyDate(newDate))
    return { description: description, date: convertToServerLocalDateString(newDate) }
}

export const createSelectableDates = (maxItems) => Array.from(Array(maxItems).keys()).map(i => createSelectableDate(new Date(), i) )

export const daysUntil = (dString) => {
    if (!dString)
        return 0
    const today = new Date()
    const d = parseJsonDateToDate(dString)
    if (d < today)
        return 0
    const millisInDay = 24 * 60 * 60 * 1000
    return Math.round((d - today) / millisInDay)
}
export function isInPast(d) {
    return d < new Date()
}
export function isInFuture(d) {
    return d > new Date()
}

