import React from "react"
import ListItem from "@mui/material/ListItem"
import List from "@mui/material/List"
import {defaultFont, grayColor, primaryColor} from "../../assets/jss/material-dashboard-react";
import {styled} from "@mui/material/styles"

export default function Footer() {

  const StyledListItem = styled(ListItem)({
    display: "inline-block !important", padding: "0px !important", width: "auto !important"
  })
  const StyledLink = styled("a")({
    color: "inherit", padding: "15px", textTransform: "uppercase", borderRadius: "3px", textDecoration: "none", position: "relative", display: "block", fontSize: "12px",
    ...defaultFont,
  })

  return (
    <footer style={{bottom: "0", borderTop: "1px solid " + grayColor[11], padding: "15px 0",}}>
      <div style={{marginTop: "30px"}}>
        <div style={{float: "left", display: "block"}}>
          <List style={{marginBottom: "0", padding: "0", marginTop: "0"}}>
            <StyledListItem>
              <StyledLink href="/">Home</StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink href="/about">About</StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink href="/register">Register Establishment</StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink href="/leeds">Browse</StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink href="/help/get-online">Help</StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink href="/partnerships">Partnerships</StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink href="/privacy">Privacy</StyledLink>
            </StyledListItem>
          </List>
        </div>
        <p style={{padding: "15px", margin: "0", fontSize: "14px", float: "right"}}>
          <span>
            <a href="/" style={{color: primaryColor[0], textDecoration: "none", backgroundColor: "transparent",}}><span>&copy; {new Date().getFullYear() + " Menu-UK Ltd"}</span></a>
          </span>
        </p>
      </div>
    </footer>
  )
}
