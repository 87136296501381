import React from 'react'
const GlobalState = React.createContext([{}, () => {}])
export default GlobalState

export const isBackdropSpinnerOpen = globalState => globalState.backdropSpinnerOpen
export const closeBackdropSpinner = (setGlobalState) => setGlobalState(state => ({...state, backdropSpinnerOpen: false}))
export const openBackdropSpinner = (setGlobalState) => setGlobalState(state => ({...state, backdropSpinnerOpen: true}))

export const isLoginModalOpen = globalState => globalState.loginModalOpen
export const closeLoginModal = (setGlobalState) => setGlobalState(state => ({...state, loginModalOpen: false}))
export const openLoginModal = (setGlobalState) => setGlobalState(state => ({...state, loginModalOpen: true}))

export const isEstablishmentRegistrationModalOpen = globalState => globalState.establishmentRegistrationModalOpen
export const closeEstablishmentRegistrationModal = (setGlobalState) => setGlobalState(state => ({...state, establishmentRegistrationModalOpen: false}))
export const openEstablishmentRegistrationModal = (setGlobalState) => setGlobalState(state => ({...state, establishmentRegistrationModalOpen: true}))

export const isReservationsModalOpen = globalState => globalState.reservationsModal
export const closeReservationsModal = (setGlobalState) => setGlobalState(state => ({...state, reservationsModal: false}))
export const openReservationsModal = (setGlobalState) => setGlobalState(state => ({...state, reservationsModal: true}))

export const isOrdersModalOpen = globalState => globalState.ordersModal
export const closeOrdersModal = (setGlobalState) => setGlobalState(state => ({...state, ordersModal: false}))
export const openOrdersModal = (setGlobalState) => setGlobalState(state => ({...state, ordersModal: true}))
