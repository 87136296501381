import React from "react";
import {AddCircle, ChangeCircleOutlined, DeleteForeverOutlined} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
    IconButton,
    TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import CustomSmallModal from "../../../layouts/CustomSmallModal";
import {
    NarrowStyledTableCell, StyledTableCell,
    StyledTableRow
} from "../../establishmentOwner/establishmentsCrud/availability/GeneralAvailabilityTable";
import {generateUuid} from "../../../functions/GeneralFunctions";

export function fetchOpeningWindows(openingHoursByDayOfWeek, name) {
    const jsonName = name === "Bank Holidays" ? "bankHolidays" : name.toLowerCase()
    return openingHoursByDayOfWeek[jsonName]
}

export default function SimplifiedEditAvailability({setModalOpen, triggerRender, name, openingHoursByDayOfWeek}) {

    const openingWindows = fetchOpeningWindows(openingHoursByDayOfWeek, name)

    const onAddDeliveryWindow = () => {
        openingWindows.push({id:generateUuid(), from:"09:00:00", to:"20:00:00"})
        triggerRender()
    }

    const onDelete = (index) => {
        openingWindows.splice(index,1)
        triggerRender()
    }

    return (
            <CustomSmallModal setModalOpen={setModalOpen} header1={name+" Opening Hours"} body={
                <div>
                  <div>
                      {
                          openingWindows.length === 0
                          ? <div style={{color: "red", fontWeight: 500}}>
                              Closed on {name}s
                              <br />
                              <Button startIcon={<ChangeCircleOutlined />} color={"primary"} onClick={onAddDeliveryWindow}>Change</Button>
                          </div>
                          : <TableContainer>
                              <Table>
                                  <TableHead>
                                      <StyledTableRow>
                                          <NarrowStyledTableCell>From</NarrowStyledTableCell>
                                          <NarrowStyledTableCell>To</NarrowStyledTableCell>
                                          <NarrowStyledTableCell></NarrowStyledTableCell>
                                      </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                      {
                                          openingWindows.map ((window, index) =>
                                              <StyledTableRow key={window.id}>
                                                  <StyledTableCell><TextField variant={"standard"} type={"time"} fullWidth InputLabelProps={{ shrink: true }} size={"small"} value={window.from} onChange={e => {window.from=e.target.value; triggerRender()}} /></StyledTableCell>
                                                  <StyledTableCell><TextField variant={"standard"} type={"time"} fullWidth InputLabelProps={{ shrink: true }} size={"small"} value={window.to} onChange={e => {window.to=e.target.value; triggerRender()}} /></StyledTableCell>
                                                  <StyledTableCell sx={{textAlign:"center"}}><IconButton onClick={() => onDelete(index)}><DeleteForeverOutlined color={"error"} /></IconButton></StyledTableCell>
                                              </StyledTableRow>
                                          )
                                      }
                                  </TableBody>
                              </Table>
                          </TableContainer>
                      }
                  </div>
                    {
                        openingWindows.length > 0 && <Button style={{marginTop:"10px"}} onClick={onAddDeliveryWindow} startIcon={<AddCircle />}>Additional Opening Hours</Button>
                    }

                  <br />
                  <Grid container spacing={1}>
                      <Grid item xs={12}>
                          <Button variant={"outlined"} fullWidth onClick={() => { setModalOpen(false); triggerRender() } }>Close</Button>
                      </Grid>
                  </Grid>
                </div>
           } />
    )
}
