import {createRoot} from "react-dom/client"
import {BrowserRouter, Route, Routes} from "react-router-dom"

import StandardLayout from "layouts/StandardLayout.js"

import "assets/css/material-dashboard-react.css?v=1.10.0"
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {makeFetch} from "./functions/GeneralFunctions";
import {Backdrop, CircularProgress} from "@mui/material";
import {CookiesProvider, useCookies} from "react-cookie";
import GlobalStateFunctions from "./functions/GlobalStateFunctions";
import ReactGA from 'react-ga4'
import Landing from "./views/contentPages/Landing";
import RedirectToWebmail from "./views/contentPages/RedirectToWebmail";
import BuyDomain from "./views/BuyDomain";

const ReservationManagement = React.lazy(() => import("./views/establishmentOwner/ReservationManagement/ReservationManagement"))
const SubscriptionManagement = React.lazy(() => import("./views/establishmentOwner/SubscriptionManagement/SubscriptionManagement"))
const AdminRouter = React.lazy(() => import('./views/admin/AdminRouter'))
const OrderManagement = React.lazy(() => import('./views/establishmentOwner/OrderManagement/OrderManagement'))
const EstablishmentCrud = React.lazy(() => import('./views/establishmentOwner/establishmentsCrud/EstablishmentCrud'))


const theme = createTheme({
    palette: {
        primary: {
            main: "rgb(124, 44, 191)",
        },
    },
})

export default function RootPage() {

    const [currentUser, setCurrentUser] = useState(false)

    const [globalState, setGlobalState] = useState({})
    const [cookies, setCookie, removeCookie] = useCookies([])

    useEffect(() => {
        // Google analytics...
        // alert("register google:"+window.location.pathname + window.location.search)
        ReactGA.initialize('G-9Z4T9V14MJ')
        ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search})
    }, [])

    useEffect(() => {
        if (cookies && !currentUser) {
            console.log("Fetching User")
            makeFetch(setGlobalState, '/api/v0/current-user/', user => {
                console.log("Found User: "+JSON.stringify(user))
                setCurrentUser(user)
                if (user.loggedIn) {
                    if (cookies.lastLoggedOutRef) {
                        //TODO R2: There is a bug with spring/webflux - once fixed, should be able to go straight to the correct page - but not fixed on 20/11/2021? This is not great user experience!
                        if (cookies.lastLoggedOutRef.url !== window.location.href)
                            window.location.href = cookies.lastLoggedOutRef.url
                        removeCookie("lastLoggedOutRef", {path: '/'})
                    }
                } else {
                    setCookie("lastLoggedOutRef", {url: window.location.href}, {path: '/'})
                }
            })
        }
    }, [currentUser, cookies, removeCookie, setCookie])

    return (
        //Need to profile, as mounting the component - even with page reloads might be faster!
        // <React.StrictMode>
            <GlobalStateFunctions.Provider value={[globalState, setGlobalState]}>
                <CookiesProvider>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <BrowserRouter>
                                <Routes>
                                    <Route path={"/"} element={<Landing currentUser={currentUser} />} />

                                    {/*IMPORTANT: Ensure that the openModalType's are regression tested when changes occur*/}
                                    <Route path={"/email-confirmed"} element={<Landing currentUser={currentUser} openModalType={"emailConfirmed"} />} />
                                    <Route path={"/reset-password"} element={<Landing currentUser={currentUser} openModalType={"resetPassword"} />} />

                                    <Route path="/admin/*" element={<AdminRouter currentUser={currentUser} />} />
                                    <Route path="/my-establishments/*" element={<EstablishmentCrud currentUser={currentUser} />} />

                                    <Route path="/order-management/*" element={<OrderManagement currentUser={currentUser} />} />
                                    <Route path="/reservation-management/*" element={<ReservationManagement currentUser={currentUser} />} />
                                    <Route path="/subscription-management/*" element={<SubscriptionManagement currentUser={currentUser} />} />
                                    <Route path="/webmail" element={<RedirectToWebmail />} />

                                    <Route path={"/buy-domain"} element={<BuyDomain />} />

                                    <Route path="/*" element={<StandardLayout currentUser={currentUser} setCurrentUser={setCurrentUser} />} />

                                </Routes>
                            </BrowserRouter>
                            {
                                globalState.backdropSpinnerOpen &&
                                <Backdrop style={{zIndex: 2000, color: '#fff'}} open={true}>
                                    <CircularProgress color="inherit"/>
                                </Backdrop>
                            }
                        </ThemeProvider>
                    </StyledEngineProvider>
                </CookiesProvider>
            </GlobalStateFunctions.Provider>
        // </React.StrictMode>
    )
}
const container = document.getElementById("root")
const root = createRoot(container)
root.render(<RootPage />)
