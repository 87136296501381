import {CircularProgress, IconButton, ListItemIcon} from "@mui/material";
import Person from "@mui/icons-material/Person";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import Divider from "@mui/material/Divider";
import {
    AdminPanelSettingsOutlined, BugReportOutlined, Logout,
    Person2Outlined, Repeat,
    SettingsOutlined,
    SubscriptionsOutlined, SupportAgent,
    TableRestaurantOutlined,
    TakeoutDiningOutlined
} from "@mui/icons-material";
import GlobalState, {
    closeEstablishmentRegistrationModal,
    closeOrdersModal,
    closeReservationsModal, isEstablishmentRegistrationModalOpen, isLoginModalOpen, isOrdersModalOpen,
    isReservationsModalOpen,
    openBackdropSpinner,
    openLoginModal,
    openOrdersModal,
    openReservationsModal
} from "../../functions/GlobalStateFunctions";
import {triggerLogout} from "../../functions/GeneralFunctions";
import React, {Suspense, useContext} from "react";
import {StyledDropdownItem} from "./NavbarLinks";
import {useLocation} from "react-router-dom";
import {fetchEstablishmentRefFromCurrentLocation} from "../../functions/UrlFunctions";
import RegisterEstablishmentModal from "../../views/modals/registerEstablishment/RegisterEstablishmentModal";

const UserOrdersModal = React.lazy(() => import("../../views/userOrdersAndBookings/UserOrdersModal"))
const LoginModal = React.lazy(() => import("../../views/modals/login/LoginModal"))

const UserReservationsModal = React.lazy(() => import('../../views/userOrdersAndBookings/UserReservationsModal'))
const UserUpdateOrRegistrationModal = React.lazy(() => import('../../views/modals/UserUpdateOrRegistrationModal'))
const ReportIssueModal = React.lazy(() => import('../../views/modals/ReportIssueModal'))

export default function TopRightMenu({currentUser, color}) {

    const [globalState, setGlobalState] = useContext(GlobalState)
    const [userMenuAnchorAndOpen, setUserMenuAnchorAndOpen] = React.useState(false)
    const [userAccountModalOpen, setUserAccountModalOpen] = React.useState(false)
    const [reportIssueModalOpen, setReportIssueModalOpen] = React.useState(false)

    const location = useLocation()

    if (!color)
        color = "white"

    const currentEstablishmentRef = fetchEstablishmentRefFromCurrentLocation(location)

    const onUserMenuClick = (event) => {
        if (userMenuAnchorAndOpen && userMenuAnchorAndOpen.contains(event.target)) {
            setUserMenuAnchorAndOpen(null)
        } else {
            setUserMenuAnchorAndOpen(event.currentTarget)
        }
    }
    const handleCloseProfile = () => {
        setUserMenuAnchorAndOpen(null)
    }

    const signInView = <span onClick={() => openLoginModal(setGlobalState)} style={{color:color, marginLeft:"15px", paddingRight: "15px", paddingTop:"5px", fontWeight:"100px", fontSize: "0.8rem", whiteSpace: "nowrap", cursor: "pointer"}}>Sign in</span>
    const loggedInView = <span aria-owns={userMenuAnchorAndOpen ? "profile-menu-list-grow" : null} aria-haspopup="true" onClick={onUserMenuClick} style={{cursor: "pointer"}}>
              <IconButton aria-label={"Toggle User Actions Menu"}>
                <Person style={{color: color}}/>
              </IconButton>
              <span style={{"paddingRight": "15px", "fontSize": "0.7rem", "marginLeft": "0px" }}>Hello, {currentUser?.firstName}</span>
          </span>

    const expandedMenu = <Popper style={{zIndex: 4000}} open={Boolean(userMenuAnchorAndOpen)} anchorEl={userMenuAnchorAndOpen} transition>
        {({TransitionProps, placement}) => (
            <Grow {...TransitionProps} id="profile-menu-list-grow" style={{transformOrigin: placement === "bottom" ? "center top" : "center bottom",}}>
                <Paper>
                    <ClickAwayListener onClickAway={handleCloseProfile}>
                        <MenuList role="menu">
                            <Divider light key={"resMenuDividerUser"}><span style={{fontSize: "0.6em", color: "grey"}}>{currentUser.name}</span></Divider>
                            <StyledDropdownItem onClick={() => { handleCloseProfile(); setUserAccountModalOpen(true) }}><ListItemIcon><Person2Outlined/></ListItemIcon> My Profile</StyledDropdownItem>
                            <StyledDropdownItem onClick={() => { handleCloseProfile(); openOrdersModal(setGlobalState) }}><ListItemIcon><TakeoutDiningOutlined/></ListItemIcon> My Orders</StyledDropdownItem>
                            <StyledDropdownItem onClick={() => { handleCloseProfile(); openReservationsModal(setGlobalState)}}><ListItemIcon><TableRestaurantOutlined/></ListItemIcon> My Reservations</StyledDropdownItem>

                            {
                                currentUser.establishments.length > 0 &&
                                [
                                    <Divider key={"resMenuDivider"} light><span style={{fontSize: "0.6em", color: "grey"}}>Establishments</span></Divider>,
                                    <StyledDropdownItem key={"resMenuMyRes"} onClick={e => { openBackdropSpinner(setGlobalState); handleCloseProfile(); window.location.href = "/my-establishments" + currentEstablishmentRef }}><ListItemIcon><SettingsOutlined/></ListItemIcon> Control Panel</StyledDropdownItem>,
                                    <StyledDropdownItem key={"resMenuIncOrders"} onClick={e => { openBackdropSpinner(setGlobalState); handleCloseProfile(); window.location.href = "/order-management" + currentEstablishmentRef }}><ListItemIcon><SubscriptionsOutlined/></ListItemIcon>Order Management</StyledDropdownItem>,
                                    <StyledDropdownItem key={"resMenuSubscriptionManagement"} onClick={e => { openBackdropSpinner(setGlobalState); handleCloseProfile(); window.location.href = "/subscription-management" + currentEstablishmentRef }}><ListItemIcon><Repeat/></ListItemIcon>Subscription Management</StyledDropdownItem>,
                                    <StyledDropdownItem key={"resMenuReservationManagement"} onClick={e => { openBackdropSpinner(setGlobalState); handleCloseProfile(); window.location.href = "/reservation-management" + currentEstablishmentRef }}><ListItemIcon><TableRestaurantOutlined/></ListItemIcon> Reservation Management</StyledDropdownItem>,
                                ]
                            }
                            <Divider light key={"resMenuDividerLast"}></Divider>
                            {
                                currentUser.role === 'ADMIN' && [
                                    <StyledDropdownItem key={"crmLink"} onClick={e => { openBackdropSpinner(setGlobalState); handleCloseProfile(); window.location.href = "/crm" }}><ListItemIcon><SupportAgent/></ListItemIcon> CRM</StyledDropdownItem>,
                                    <StyledDropdownItem key={"adminLink"} onClick={e => { openBackdropSpinner(setGlobalState); handleCloseProfile(); window.location.href = "/admin/" }}><ListItemIcon><AdminPanelSettingsOutlined/></ListItemIcon> System Admin</StyledDropdownItem>
                                ]
                            }
                            <StyledDropdownItem onClick={() => { handleCloseProfile(); setReportIssueModalOpen(true) }}><ListItemIcon><BugReportOutlined/></ListItemIcon> Report Issue</StyledDropdownItem>
                            <StyledDropdownItem onClick={() => { openBackdropSpinner(setGlobalState); handleCloseProfile(); triggerLogout() }}><ListItemIcon><Logout/></ListItemIcon> Logout</StyledDropdownItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )}
    </Popper>


    return <div style={{color: color, display: "inline-block"}}>
        { currentUser && currentUser.loggedIn ? loggedInView : signInView }
        { expandedMenu }
        <Suspense fallback={<CircularProgress color="inherit" style={{position:"fixed", top:"50%", left:"50%"}} />}>
            { ((currentUser && currentUser.loggedIn && !currentUser.isRegistered) || userAccountModalOpen) && <UserUpdateOrRegistrationModal setOpen={setUserAccountModalOpen} /> }
            { reportIssueModalOpen && <ReportIssueModal currentUser={currentUser} setModalOpen={setReportIssueModalOpen} /> }
            { isReservationsModalOpen(globalState) && <UserReservationsModal closeModal={() => { closeReservationsModal(setGlobalState) } } /> }
            { isOrdersModalOpen(globalState) && <UserOrdersModal closeModal={() => { closeOrdersModal(setGlobalState) } } /> }
            { isLoginModalOpen(globalState) && <LoginModal /> }
            { isEstablishmentRegistrationModalOpen(globalState) && <RegisterEstablishmentModal closeModal={() => closeEstablishmentRegistrationModal(setGlobalState)} /> }
        </Suspense>
    </div>
}
